import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    width: '100%',
    height: '100vh'
  },
  contentContainer: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100% - 75px)',
    height: 'auto'
  },
  pageContent: {
    width: 'calc(100% - 287px)',
    padding: '20px 50px'
  }
});
