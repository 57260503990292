import * as React from 'react';
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from 'dayjs';
import { useStyles } from './styles';
import {
    Box,
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import StoryImage from "./StoryImage";
import StoryVideo from "./StoryVideo";
import {IOneStory} from "../../store/types";
import {Stories} from '../../store/slices'
import {useDispatch} from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import {useNavigate} from "react-router";


const ProductVariants = [
    {title: 'Mini 10\"', value: 7},
    {title: '16\" Shaped Pillow', value: 1},
    {title: '22\" Shaped Pillow', value: 2},
    {title: '30\" Shaped Pillow', value: 3},
    {title: '60\" Shaped Pillow', value: 11},
    {title: 'Keychain Pillow', value: 4},
    {title: '16\"x16\" Square Pillow', value: 5},
    {title: '18\"x18\" Square Pillow', value: 6},
    {title: '20\"x20\" Square Pillow', value: 10},
]

const StoryOccasion = [
    {title: 'Birthday Gift', value: 1},
    { title: 'Christmas Gift', value: 2 },
    { title: 'Father’s Day Gift', value: 3 },
    { title: 'Graduation Gift', value: 4 },
    { title: 'Mother’s Day Gift', value: 5 },
    { title: 'Wedding Gift', value: 6 },
    { title: 'Back To College Gift', value: 7 },
    { title: 'Passing of pet', value: 8 },
    { title: 'Other', value: 9 },
    { title: 'Valentines day Gift', value: 10 },
    { title: 'Anniversary Gift', value: 11 },
    { title: 'Graduation Gift', value: 12 },
    { title: 'Pride Month Gift', value: 13 },
    { title: 'Easter Gift', value: 14 },
    { title: 'Memorial Day Gift', value: 15 },
    { title: '4th Of July Gift', value: 16 },
    { title: 'Halloween Gift', value: 17 },
    { title: 'Thanksgiving Gift', value: 18 },
    { title: 'New Year’s Gift', value: 19 },
    { title: 'Baby Shower Gift', value: 20 },
    { title: 'Housewarming Gift', value: 21 },
    { title: 'Retirement Gift', value: 22 },
    { title: 'Engagement Gift', value: 23 },
    { title: 'Promotion Gift', value: 24 },
    { title: 'Back To School Gift', value: 25 },
    { title: 'Sports Achievements Gift', value: 26 },
    { title: 'Memorial Gift', value: 27 }
]

const StoryRecipient = [
    { title: 'Prefer not to answer', value: 1 },
    { title: 'Son', value: 2 },
    { title: 'Daughter', value: 3 },
    { title: 'Parent', value: 4 },
    { title: 'Friend', value: 5 },
    { title: 'Co-Worker', value: 6 },
    { title: 'Grandparent', value: 7 },
    { title: 'Myself', value: 8 },
    { title: 'Spouse', value: 9 },
    { title: 'Boyfriend/Girlfriend', value: 10 },
    { title: 'Child', value: 11 },
    { title: 'Other', value: 12 }
]

const schema = yup.object().shape({
    firstName: yup.string().required("Name is required"),
    email: yup.string().email("Incorrect format").required("E-mail is required"),
    productId: yup.string().required("Product is required"),
    occasionId: yup.string().required("Occasion is required"),
    recipientId: yup.string().required("Recipient is required"),
    recommendation: yup.string().required("Recommendation is required"),
    reason: yup.string().required("Reason is required"),
    impact: yup.string().required("Impact is required"),
});



export default function StoryForm(props: any) {
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });
    const [mediaData, setMediaData] = useState<IOneStory>({})
    const [pending, setPending] = useState(false);
    const [storyDate, setStoryDate] = useState<any>(dayjs(new Date()))
    const classes = useStyles();
    const navigator = useNavigate();
    const dispatch = useDispatch()
    const formSubmit = (formData) => {
        setPending(true);
        const data = {
            author: {
                email: formData.email,
                firstName: formData.firstName,
            },
            story: {
                recipientId: formData.recipientId,
                occasionId: formData.occasionId,
                productId: formData.productId,
                imageLink: mediaData.imageLink,
                impact: formData.impact,
                reason: formData.reason,
                recommendation: formData.recommendation,
                videoLink: mediaData.videoLink,
                date: `${storyDate.format('YYYY-MM-DDTHH:mm:ss')}.000Z`
            }
        }
        dispatch<any>(Stories.CreateStory({onSuccess: (rest)=> {navigator(`/stories/${rest.storyId}/details`);}, formData:data})).then(()=>{setPending(false)})
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMediaData({
            ...mediaData,
            [name]: value,
        });
    }
    return (
        <div className={classes.wrapper}>
            {pending && <div className={classes.giftCardLoader}> <Skeleton animation="wave" width={'100%'} height={'100%'} variant="rounded" /></div>}

            <div className={classes.header}><span>Story</span></div>
            <Box component="form" onSubmit={handleSubmit(formSubmit)} noValidate sx={{ mt: 1 }}>
                <div className={classes.inputWrap}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormControl sx={{ m: 1,  width: '30ch' }}>
                        <DateTimePicker
                            views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
                            value={storyDate}
                            maxDateTime={dayjs(new Date())}
                            onChange={(newValue)=>setStoryDate(newValue)}
                        />
                        </FormControl>
                    </LocalizationProvider>
                </div>
                <div className={classes.inputWrap}>
                    <FormControl sx={{ m: 1,  width: '30ch' }}>
                        <TextField
                            label="Name"
                            variant="outlined"
                            {...register('firstName')}
                            error={Boolean(errors.firstName)}
                            helperText={errors.firstName && errors?.firstName?.message?.toString()}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1,  width: '30ch' }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            {...register('email')}
                            error={Boolean(errors.email)}
                            helperText={errors.email && errors?.email?.message?.toString()}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1,  width: '30ch' }}>
                        <InputLabel id="demo-simple-select-helper-label">Product</InputLabel>
                        <Select
                            label="Product"
                            variant="outlined"
                            {...register('productId')}
                            error={Boolean(errors.productId)}

                        >
                            {ProductVariants.map((product)=>
                                <MenuItem key={`${product.value}${product.title}`} value={product.value}>{product.title}</MenuItem>
                            )}
                        </Select>
                        {errors.productId &&
                            <FormHelperText error>
                            {errors?.productId?.message?.toString()}
                            </FormHelperText>
                        }

                    </FormControl>
                 </div>
                <div className={classes.inputWrap}>
                    <StoryImage onChange={handleInputChange}/>
                    <div className={classes.rightColumn}>
                        <div className={classes.columnInputs}>
                            <FormControl sx={{ m: 1,  width: '30ch' }}>
                                <InputLabel id="demo-simple-select-helper-label">Occasion</InputLabel>
                                <Select
                                    label="Occasion"
                                    variant="outlined"
                                    // name="occasionId"
                                    // onChange={(event) =>handleInputChange(event)}
                                    {...register('occasionId')}
                                    error={Boolean(errors.occasionId)}
                                >
                                    {StoryOccasion.map((occasion)=>
                                        <MenuItem key={`${occasion.value}${occasion.title}`} value={occasion.value}>{occasion.title}</MenuItem>
                                    )}
                                </Select>
                                {errors.occasionId &&
                                    <FormHelperText error>
                                        {errors?.occasionId?.message?.toString()}
                                    </FormHelperText>
                                }
                            </FormControl>
                            <FormControl sx={{ m: 1,  width: '30ch' }}>
                                <InputLabel id="demo-simple-select-helper-label">Recipient</InputLabel>
                                <Select
                                    label="Occasion"
                                    variant="outlined"
                                    // name="recipientId"
                                    // onChange={(event) =>handleInputChange(event)}
                                    {...register('recipientId')}
                                    error={Boolean(errors.recipientId)}
                                >
                                    {StoryRecipient.map((recipient)=>
                                        <MenuItem key={`${recipient.value}${recipient.title}`} value={recipient.value}>{recipient.title}</MenuItem>
                                    )}
                                </Select>
                                {errors.recipientId &&
                                    <FormHelperText error>
                                        {errors?.recipientId?.message?.toString()}
                                    </FormHelperText>
                                }
                            </FormControl>
                        </div>
                        <FormControl sx={{ m: 1,  width: 'calc(100% - 16px)' , marginTop: '24px'}}>
                            {/*<InputLabel id="demo-simple-select-helper-label">Reason</InputLabel>*/}
                            <TextField
                                id="outlined-multiline-static"
                                label="Reason"
                                multiline
                                rows={8}
                                {...register('reason')}
                                error={Boolean(errors.reason)}
                                helperText={errors.reason && errors?.reason?.message?.toString()}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={classes.inputWrap} >
                    <StoryVideo onChange={handleInputChange}/>
                    <div className={classes.rightColumn}>
                    <FormControl sx={{ m: 1, width: '62ch'  ,maxWidth: 'calc(100% - 16px)', margin: '8px' }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Recommendation"
                            multiline
                            rows={5}
                            {...register('recommendation')}
                            error={Boolean(errors.recommendation)}
                            helperText={errors.recommendation && errors?.recommendation?.message?.toString()}
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '62ch'  ,maxWidth: 'calc(100% - 16px)', margin: '8px' }}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Impact"
                            multiline
                            rows={5}
                            {...register('impact')}
                            error={Boolean(errors.impact)}
                            helperText={errors.impact && errors?.impact?.message?.toString()}
                        />
                    </FormControl>
                    </div>
                </div>
                {Object.keys(mediaData).length === 2 &&
                    <div className={classes.buttonWrap}><Button type={"submit"} variant="contained">Save changes</Button></div>
                }
            </Box>
        </div>
    );
}