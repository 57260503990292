import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  unknownScreen: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '80vh',
    flexDirection: 'column'
  },
  error: {
    fontSize: '50px',
    color: 'grey',
    paddingBottom: '50px'
  },
  label: {
    fontSize: '60px',
    color: '#047d95',
    paddingBottom: '60px',
    fontWeight: 600,
  },
  link: {
    fontSize: '20px',
    color: '#1da1f2',
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'underline'
  },
});

export default useStyles;
