import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** Components */
import Skeleton from '@mui/material/Skeleton';
import PageContainer from '../../components/PageContainer';
import AuthorsTable from '../../components/AthorsTable';
/** Selectors */
import {Authors} from '../../store/slices'

const DEFAULT_PAGING_STATE = {
  perPage: 25,
  page: 1,
  total: 0
};


const AuthorsPage = () => {
  const {
    authors,
    status,
    queryParams
  } = useSelector<any, { authors: any, status: string , queryParams: any}>(state => ({
    authors: Object.values(state.authors.data),
    status: state.authors.status,
    queryParams: state.authors.queryParams
  }))

  const dispatch = useDispatch();
  const loadAuthors = (params) => {
    // @ts-ignore
    dispatch<any>(Authors.getAuthors(params));
  };
  useEffect(() => {
    loadAuthors({
      page: queryParams?.page || DEFAULT_PAGING_STATE.page,
      perPage: queryParams?.perPage || DEFAULT_PAGING_STATE.perPage,
    });
  }, [])
  return (
    <PageContainer>
      <h2 style={{marginTop: 0, color:'#047d95', marginBottom: '40px', fontWeight: 600}}>Authors</h2>
      {(authors?.length > 0 && status !== 'loading') &&
          <AuthorsTable authors={authors} paging={{...DEFAULT_PAGING_STATE, ...queryParams}} pagingUpdate={loadAuthors}/>
      }
      {authors?.length < 1 && status !== 'loading' && <div>Don't found result</div>}
      {status === 'loading' &&
        <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
    </PageContainer>

  );
};

export default AuthorsPage;
