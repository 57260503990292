export const auth = ({dispatch}) => next => (action) => {
    next(action);
}
// export const authMiddleware = (store) => (next) => (action) => {
//
//     const token = store.getState().auth.data.token;
//     console.log('authMiddleware', token)
//     if (token) {
//         action.headers = {
//             ...action.headers,
//             Authorization: `Bearer ${encodeURIComponent(token)}`,
//         };
//     }
//
//     return next(action);
// };