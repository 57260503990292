import React from 'react';
import {useNavigate} from "react-router";
import useStyles from './styles';
import Header from "../../components/Header";
import PageContainer from "../../components/PageContainer";



const WithoutAccess = () => {
  const classes = useStyles();
    const navigator = useNavigate();
  return (
<div className={classes.unknownScreen}>
    <PageContainer>
        <div className={classes.wrapper}>
            <div className={classes.label2}>Coming soon...</div>
        </div>
    </PageContainer>
</div>
  );
};

export default WithoutAccess;
