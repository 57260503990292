import './App.css';
import { Amplify} from "aws-amplify";
import {Authenticator, withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {Provider} from "react-redux";
import store from './store';
import { OpenCvProvider } from "opencv-react";

import Root from "./components/Root";

const signUpAttributes = ['email'];

Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_REGION,
    aws_user_pools_id: process.env.REACT_APP_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_WEB_CLIENT_ID,
});

function App() {
  return (
            <Provider store={store}>
                    <Root />
            </Provider>
  );
}

export default withAuthenticator(App,{signUpAttributes});
