import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router";
import useStyles from './styles';
import Header from "../../components/Header";
// import { OpenCvProvider, useOpenCv } from "opencv-react";
import PageContainer from "../../components/PageContainer";
import {Button, CircularProgress} from "@mui/material";
import * as fs from "fs";
import axios from 'axios';

interface ImageQuality {
    // resolution24: number;
    // resolution16: number;
    // resolution10: number;
    // blur: number;
    // sharpness: number;
    // noise: number;
}
type BlobType = Blob | undefined;

const ImageQuality = () => {
  const classes = useStyles();
    const navigator = useNavigate();
    const [image, setImage] = useState<any| null>(null);
    const [pilow, setPilow] = useState<any| null>(null);
    const [imageQuality, setImageQuality] = useState<any| null>();
    const [pillowQuality, setPillowQuality] = useState<any| null>();
    const [aiImageQuality, setAiImageQuality] = useState<any| null>();
    const [imageFilePath, setImageFilePath] = useState('https://wxtechhk.oss-cn-hongkong.aliyuncs.com/tasks/output/scale/f1a9da0b-1110-4044-ad05-35c5ca414249.png?Expires=1684938762&OSSAccessKeyId=LTAI5tGjJnh66c1txANiRBQN&Signature=WKBPMqugE9/nz8E3gNVqWH3plMA%3D');
    const [file, setFile] = useState();
    const [aiImage, setAiImage]= useState<any| null>();
    const [loading, setLoading] = useState(false)
    const [fullImageRating, setFullImageRating] = useState<any| null>();
    const [pillowRating, setPillowRating] = useState<any| null>();

    const bufferToBase64 = (buffer) => {
        const arr = new Uint8Array(buffer);

        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }
    // const imageToDataUrl = async (src) => {
    //     const img = await new Promise((resolve) => {
    //         const nextImg = new Image();
    //         nextImg.crossOrigin = "Anonymous";
    //         nextImg.src = src;
    //         nextImg.onload = () => {
    //             resolve(nextImg);
    //         }
    //     })
    //
    //     const canvas = document.createElement('canvas');
    //     const ctx = canvas.getContext('2d');
    //     ctx.canvas.width = img.width;
    //     ctx.canvas.height = img.height;
    //
    //     ctx.drawImage(img, 0, 0);
    //     const dataUrl = canvas.toDataURL();
    //     ctx.clearRect(0, 0, 1, 1);
    //     canvas.remove();
    //
    //     return dataUrl;
    // }

    function imageToBase64(file: File): Promise<string | null> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result as string;
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(file);
        });
    }
    const getImage = async (src) => {
        const img = await new Promise((resolve) => {
            const nextImg = new Image();
            nextImg.src = src;
            nextImg.onload = () => {
                resolve(nextImg);
            }
        });
        return img;
    }
    const trimImage = async (originImage, maskImage) => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // setCanvasSize(ctx, originImage.width);
        if(ctx){
            ctx.canvas.width = originImage.width;
            ctx.canvas.height = originImage.height;
            ctx.drawImage(originImage, 0, 0);
            ctx.globalCompositeOperation = "destination-in";
            ctx.drawImage(maskImage, 0, 0, maskImage.width, maskImage.height, 0, 0, originImage.width, originImage.height);

            // Reset the global composite operation
            ctx.globalCompositeOperation = "source-over";
        }



        //test
        let canvas_trim = "";
        async function trimCanvas(c) {
            // if (canvas_trim != "") {
            //     let context1 = canvas_trim.getContext('2d');
            //     context1.clearRect(0, 0, 1, 1);
            //     canvas_trim.remove();
            // }

            const canvas_trim = document.createElement('canvas');

            let ctx = c.getContext('2d'),
                copy = canvas_trim.getContext('2d'),
                pixels = ctx.getImageData(0, 0, c.width, c.height),
                l = pixels.data.length,
                i,
                bound = {
                    top: null,
                    left: null,
                    right: null,
                    bottom: null
                },
                x, y;

// Iterate over every pixel to find the highest
// and where it ends on every axis ()
            for (i = 0; i < l; i += 4) {
                if (pixels.data[i + 3] !== 0) {
                    x = (i / 4) % c.width;
                    y = ~~((i / 4) / c.width);

                    if (bound.top === null) {
                        bound.top = y;
                    }

                    if (bound.left === null) {
                        bound.left = x;
                    } else if (x < bound.left) {
                        bound.left = x;
                    }

                    if (bound.right === null) {
                        bound.right = x;
                    } else if (bound.right < x) {
                        bound.right = x;
                    }

                    if (bound.bottom === null) {
                        bound.bottom = y;
                    } else if (bound.bottom < y) {
                        bound.bottom = y;
                    }
                }
            }

// Calculate the height and width of the content

            // @ts-ignore
            let trimHeight = bound.bottom - bound.top, trimWidth = bound.right - bound.left,
                trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);
            // @ts-ignore
            copy.canvas.width = trimWidth;copy.canvas.height = trimHeight;copy.putImageData(trimmed, 0, 0);
            // @ts-ignore
            return copy.canvas;
        }
        const trimedImage = await trimCanvas(canvas)
        return trimedImage.toDataURL('image/png');
    }

    function calculateSize(img, maxWidth, maxHeight) {
        let width = img.width;
        let height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;

            }
        }

        return [width, height];
    }
   const resizeImageBG = async (src,
                                        size,
                                        padding = 0,
                                        {
                                            type = 'image/png',
                                            willReadFrequently = true
                                        } = {type: 'image/png', willReadFrequently: true}
    ) => {
        const img = await new Promise((resolve) => {
            const nextImg = new Image();
            nextImg.crossOrigin = "Anonymous";
            nextImg.src = src;
            nextImg.onload = () => {
                resolve(nextImg);
            }
        })

        const [newWidth, newHeight] = calculateSize(img, 800, 800);
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const ctx = canvas.getContext("2d");
       // @ts-ignore
        ctx.drawImage(img, 0, 0, newWidth, newHeight);

        let newDataUrl = canvas.toDataURL(type, 0.8);
       // @ts-ignore
        ctx.clearRect(0, 0, 1, 1);
        canvas.remove()


        return newDataUrl;
    }


   const getImageWithoutBG = useCallback( async (base64Data) =>{

       const removeBGDataUrl = await resizeImageBG(
           base64Data,
           512,
           0,
           {type: 'image/jpeg'}
       );

            const formData = new FormData();
            let img;
            debugger;
            formData.append('size', 'auto');
            formData.append('image_file_b64', removeBGDataUrl);

            // @ts-ignore
       const request = await axios({
                method: 'post',
                url: 'https://api.remove.bg/v1.0/removebg',
                data: formData,
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-Api-Key': 'seEDzgcaaL5HRE4HBwdD868f',
                },
                encoding: null,
            });

            img = `data:image/png;base64,${bufferToBase64(request.data)}`
            const masckImage = await getImage(img);
            const originImage = await getImage(base64Data);
            const maskImage = await getImage(img);
            let dataUrl = await trimImage(originImage,maskImage);
            setPilow(dataUrl);
            return dataUrl;
    }, [image])

    function convertToPilowData(dataURL) {
        return new Promise((resolve, reject) => {
            var parts = dataURL.split(',');
            var contentType = parts[0].split(':')[1];
            var byteString = atob(parts[1]);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var uint8Array = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
                uint8Array[i] = byteString.charCodeAt(i);
            }

            var blob = new Blob([arrayBuffer], { type: contentType });

            var reader = new FileReader();
            reader.onload = function(e) {
                // var readAsDataURLResult = e.target.result;
                // resolve(readAsDataURLResult); // Викликаємо resolve з результатом
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    // @ts-ignore
                    ctx.drawImage(img, 0, 0);
                    // @ts-ignore
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    resolve(imageData);
                };
                img.onerror = reject;
                img.src = e.target?.result as string;
            };
            reader.onerror = function(e) {
                reject(e); // Викликаємо reject при помилці
            };
            reader.readAsDataURL(blob);
        });
    }



   async function handleFileInputChange(event) {
        if( !event.target.files?.[0]){
            return false;
        }
       setLoading(true)
       setImage(null);
       setImageQuality(null);
       setAiImageQuality(null);
       setAiImage(null);
        const fileInput = event.target as HTMLInputElement;
        const file = fileInput.files?.[0];
        // @ts-ignore
       setFile(file);
        if (file) {
            try {
                const imageData = await getImageData(file);
                console.log('startImage',imageData);
                // Perform image quality analysis using the imageData
                const blur = isImageBlurred(imageData, 100);
                const sharpness = calculateSharpness(imageData);
                const imageQualityResult = await calculateImageQuality(imageData);
                const imageDATAresult = {...imageQuality, ...imageQualityResult, sharpness, blur, width:imageData.width, height: imageData.height };
                setImageQuality({...imageQuality, ...imageQualityResult, sharpness, blur, width:imageData.width, height: imageData.height });
                //checkImageQuality

                console.log(imageDATAresult, 'TESTQUALITY');
                let imageValue10;
                if(imageDATAresult.resolution10*1 > 140){
                    imageValue10=3
                }else if(imageDATAresult.resolution10*1 < 140 && imageDATAresult.resolution10*1 > 72){
                    imageValue10=2
                } else {
                    imageValue10=1
                }
                let imageValue16;
                if(imageDATAresult.resolution16*1 > 140){
                    imageValue16=3
                }else if(imageDATAresult.resolution16*1 < 140 && imageDATAresult.resolution16*1 > 72){
                    imageValue16=2
                } else {
                    imageValue16=1
                }
                let imageValue24;
                if(imageDATAresult.resolution24*1 > 140){
                    imageValue24=3
                }else if(imageDATAresult.resolution24*1 < 140 && imageDATAresult.resolution24*1 > 72){
                    imageValue24=2
                } else {
                    imageValue24=1
                }

                const imageBlurConst10 = imageDATAresult.blur*1 * (imageDATAresult.resolution10*1/72);
                const imageBlurConst16 = imageDATAresult.blur*1 * (imageDATAresult.resolution16*1/72);
                const imageBlurConst24 = imageDATAresult.blur*1 * (imageDATAresult.resolution24*1/72);

                if(imageValue10 != 1){
                    if (imageBlurConst10 <= 8 && imageBlurConst10 > 2) {
                        imageValue10= imageValue10 != 2 ? imageValue10 - 1 : imageValue10;
                    } else if(imageBlurConst10 <= 2) {
                        imageValue10 = 1;
                    }
                }
                if(imageValue16 != 1){
                    if (imageBlurConst16 <= 8 && imageBlurConst16 > 2) {
                        imageValue16= imageValue16 != 2 ? imageValue16 - 1 : imageValue16;
                    } else if(imageBlurConst16 <= 2) {
                        imageValue16 = 1;
                    }
                }

                if(imageValue24 != 1) {
                    if (imageBlurConst24 <= 8 && imageBlurConst24 > 2) {
                        imageValue24= imageValue24 != 2 ? imageValue24 - 1 : imageValue24;
                    } else if(imageBlurConst24 <= 2) {
                        imageValue24 = 1;
                    }
                }
                // @ts-ignore
                setFullImageRating({imageValue10: imageValue10, imageValue16: imageValue16, imageValue24: imageValue24})

                const base64Data = await imageToBase64(file);
                const pillowImage = await getImageWithoutBG(base64Data);
                const pillowData = await convertToPilowData(pillowImage);
                const blurPillow = isImageBlurred(pillowData, 100);
                const sharpnessPillow = calculateSharpness(pillowData);
                const imageQualityResultPillow = await calculateImageQuality(pillowData);
                // @ts-ignore
                const pillowDATAresult = {...imageQualityResultPillow, sharpnessPillow, blurPillow, width: pillowData.width, height: pillowData.height };
                // @ts-ignore
                setPillowQuality({...imageQualityResultPillow, sharpnessPillow, blurPillow, width: pillowData.width, height: pillowData.height })
                //checkPillowQuality

                let pillowValue10;
                // @ts-ignore
                if(pillowDATAresult.resolution10*1 > 140){
                    pillowValue10=3
                    // @ts-ignore
                }else if(pillowDATAresult.resolution10*1 < 140 && pillowDATAresult.resolution10*1 > 72){
                    pillowValue10=2
                } else {
                    pillowValue10=1
                }
                let pillowValue16;
                // @ts-ignore
                if(pillowDATAresult.resolution16*1 > 140){
                    pillowValue16=3
                    // @ts-ignore
                }else if(pillowDATAresult.resolution16*1 < 140 && pillowDATAresult.resolution16*1 > 72){
                    pillowValue16=2
                } else {
                    pillowValue16=1
                }
                let pillowValue24;
                // @ts-ignore
                if(pillowDATAresult.resolution24*1 > 140){
                    pillowValue24=3
                    // @ts-ignore
                }else if(pillowDATAresult.resolution24*1 < 140 && pillowDATAresult.resolution24*1 > 65){
                    pillowValue24=2
                } else {
                    pillowValue24=1
                }
                // @ts-ignore
                const pilowBlurConst10 = pillowDATAresult.blurPillow*1 * (pillowDATAresult.resolution10*1/72);
                // @ts-ignore
                const pilowBlurConst16 = pillowDATAresult.blurPillow*1 * (pillowDATAresult.resolution16*1/72);
                // @ts-ignore
                const pilowBlurConst24 = pillowDATAresult.blurPillow*1 * (pillowDATAresult.resolution24*1/65);

                if(pillowValue10 != 1){
                    if (pilowBlurConst10 <= 8 && pilowBlurConst10 > 2) {
                        pillowValue10= pillowValue10 != 2 ? pillowValue10 - 1 : pillowValue10;
                    } else if(pilowBlurConst10 <= 2) {
                        pillowValue10 = 1;
                    }
                }
                if(pillowValue16 != 1){
                    if (pilowBlurConst16 <= 8 && pilowBlurConst16 > 2) {
                        pillowValue16= pillowValue16 != 2 ? pillowValue16 - 1 : pillowValue16;
                    } else if(pilowBlurConst16 <= 2) {
                        pillowValue16 = 1;
                    }
                }

                if(pillowValue24 != 1) {
                    if (pilowBlurConst24 <= 8 && pilowBlurConst24 > 2.7) {
                        pillowValue24= pillowValue24 != 2 ? pillowValue24 - 1 : pillowValue24;
                    } else if(pilowBlurConst24 <= 2.7) {
                        pillowValue24 = 1;
                    }
                }
                // @ts-ignore
                setPillowRating({pillowValue10, pillowValue16, pillowValue24})
                setLoading(false);
            } catch (error) {
                console.error('Error processing the image:', error);
                setLoading(false);
            }
        }
    }
    function isImageBlurred(imageData, threshold) {
        const data = imageData.data;
        const width = imageData.width;
        const height = imageData.height;
        let sum = 0;

        // Laplacian operator kernel
        const kernel = [
            [0, 1, 0],
            [1, -4, 1],
            [0, 1, 0]
        ];

        // Loop through each pixel and apply the Laplacian operator
        for (let y = 1; y < height - 1; y++) {
            for (let x = 1; x < width - 1; x++) {
                let intensity = 0;

                // Apply the kernel to the pixel and its neighbors
                for (let ky = -1; ky <= 1; ky++) {
                    for (let kx = -1; kx <= 1; kx++) {
                        const pixelIndex = ((y + ky) * width + (x + kx)) * 4;
                        const pixelIntensity = (data[pixelIndex] + data[pixelIndex + 1] + data[pixelIndex + 2]) / 3;
                        intensity += kernel[ky + 1][kx + 1] * pixelIntensity;
                    }
                }

                sum += Math.abs(intensity);
            }
        }

        // Calculate the average intensity change
        const average = sum / ((width - 2) * (height - 2));
        console.log('blur lvl', average);
        return average.toFixed(2);
    }

    function getImageData(file: File): Promise<ImageData> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.crossOrigin = "Anonymous";
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
                    canvas.width = img.width;
                    canvas.height = img.height;
                    // @ts-ignore
                    ctx.drawImage(img, 0, 0);
                    // @ts-ignore
                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    setImage(canvas.toDataURL())
                    resolve(imageData);
                };
                img.onerror = reject;
                img.src = event.target?.result as string;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }

    function getAIImageData(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = () => {
                const canvasI = document.createElement("canvas");
                const ctx = canvasI.getContext("2d");
                canvasI.width = img.width;
                canvasI.height = img.height;
                // @ts-ignore
                ctx.drawImage(img, 0, 0);
                // @ts-ignore
                const imageData = ctx.getImageData(0, 0, canvasI.width, canvasI.height);
                resolve(imageData);
            };
            img.onerror = reject;
            img.src = url;
        });
    }

    // Function to calculate image sharpness using Sobel filter
    function calculateSharpness(imageData) {
        const width = imageData.width;
        const height = imageData.height;

        // Create temporary canvas and context for image processing
        const tempCanvas = document.createElement('canvas');
        const tempCtx = tempCanvas.getContext('2d');
        tempCanvas.width = width;
        tempCanvas.height = height;

        // Convert the image data to grayscale
        // @ts-ignore
        tempCtx.putImageData(imageData, 0, 0);
        // @ts-ignore
        const grayscaleImageData = tempCtx.getImageData(0, 0, width, height);
        const grayscaleData = grayscaleImageData.data;
        for (let i = 0; i < grayscaleData.length; i += 4) {
            const r = grayscaleData[i];
            const g = grayscaleData[i + 1];
            const b = grayscaleData[i + 2];
            const average = (r + g + b) / 3;
            grayscaleData[i] = average;
            grayscaleData[i + 1] = average;
            grayscaleData[i + 2] = average;
        }

        // Apply the Sobel filter to calculate the gradients
        const sobelData = new Float32Array(width * height);
        const sobelThreshold = 50; // Adjust this threshold to change the sensitivity
        for (let y = 1; y < height - 1; y++) {
            for (let x = 1; x < width - 1; x++) {
                const index = y * width + x;
                const gx = (
                    grayscaleData[(y - 1) * width + (x - 1)] -
                    grayscaleData[(y - 1) * width + (x + 1)] +
                    grayscaleData[y * width + (x - 1)] * 2 -
                    grayscaleData[y * width + (x + 1)] * 2 +
                    grayscaleData[(y + 1) * width + (x - 1)] -
                    grayscaleData[(y + 1) * width + (x + 1)]
                );

                const gy = (
                    grayscaleData[(y - 1) * width + (x - 1)] +
                    grayscaleData[(y - 1) * width + x] * 2 +
                    grayscaleData[(y - 1) * width + (x + 1)] -
                    grayscaleData[(y + 1) * width + (x - 1)] -
                    grayscaleData[(y + 1) * width + x] * 2 -
                    grayscaleData[(y + 1) * width + (x + 1)]
                );

                const gradient = Math.sqrt(gx * gx + gy * gy);
                sobelData[index] = gradient > sobelThreshold ? gradient : 0;
            }
        }

        // Calculate the average sharpness
        let sharpness = 0;
        for (let i = 0; i < sobelData.length; i++) {
            sharpness += sobelData[i];
        }
        sharpness /= sobelData.length;

        return sharpness.toFixed(2);
    }


    function calculateImageQuality(imageData: any): Promise<ImageQuality> {
        return new Promise((resolve, reject) => {
            const { data, width, height } = imageData;
            // Calculate the resolution of the image
            let bigestSide;
            if (width>= height){
                bigestSide = width
            } else {
                bigestSide = height
            }
            const resolution24 = (bigestSide / 24).toFixed(2);
            const resolution16 = (bigestSide / 16).toFixed(2);
            const resolution10 = (bigestSide / 10).toFixed(2);

            // Convert the image to grayscale
            const grayscaleData = new Uint8ClampedArray(width * height);
            for (let i = 0; i < data.length; i += 4) {
                const r = data[i];
                const g = data[i + 1];
                const b = data[i + 2];
                grayscaleData[i / 4] = Math.round(0.2989 * r + 0.5870 * g + 0.1140 * b);
            }

            // Calculate the blur level of the image using Laplacian filter
            // const laplacian = new Float32Array(width * height);
            // const laplacianMat = cv.matFromArray(height, width, cv.CV_8UC4, data);
            // cv.cvtColor(laplacianMat, laplacianMat, cv.COLOR_RGBA2GRAY);
            // cv.Laplacian(laplacianMat, laplacianMat, cv.CV_32F);
            // cv.MatToImageData(laplacianMat, { data: laplacian }, 1);
            // const blur = Math.abs(cv.meanStdDev(laplacian).stddev[0]);

            // Calculate the sharpness of the image using Sobel filter
            // const sobelX = new Float32Array(width * height);
            // const sobelY = new Float32Array(width * height);
            // const sobelXMat = cv.matFromArray(height, width, cv.CV_8UC4, data);
            // cv.Sobel(sobelXMat, sobelXMat, cv.CV_32F, 1, 0);
            // cv.MatToImageData(sobelXMat, { data: sobelX }, 1);
            // const sobelYMat = cv.matFromArray(height, width, cv.CV_8UC4, data);
            // cv.Sobel(sobelYMat, sobelYMat, cv.CV_32F, 0, 1);
            // cv.MatToImageData(sobelYMat, { data: sobelY }, 1);
            // let sharpness = 0;
            // for (let i = 0; i < sobelX.length; i++) {
            //     sharpness += Math.sqrt(Math.pow(sobelX[i], 2) + Math.pow(sobelY[i], 2));
            // }
            // sharpness /= sobelX.length;

            // Calculate the noise level of the image
            const mean = grayscaleData.reduce((acc, val) => acc + val, 0) / grayscaleData.length;
            const stdDev = Math.sqrt(grayscaleData.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / grayscaleData.length);
            const noise = (stdDev / mean).toFixed(2);

            resolve({ resolution24, resolution10, resolution16, noise });
        });
    }

    const processImage = () => {
            setLoading(true);
        // getAIImageData(imageFilePath)
        //     .then(async (imageData:any) => {
        //         // Process the image data as needed
        //         console.log(imageData);
        //         const blur = isImageBlurred(imageData, 100);
        //         const sharpness = calculateSharpness(imageData);
        //         // console.log('BLURR');
        //         // console.log(`Image is blurred: ${blur}`);
        //         const imageQualityResult = await calculateImageQuality(imageData);
        //         console.log(imageQualityResult);
        //         // @ts-ignore
        //         setAiImageQuality({
        //             ...imageQuality, ...imageQualityResult,
        //             sharpness,
        //             blur,
        //             width: imageData.width,
        //             height: imageData.height
        //         })
        //         setLoading(false)
        //
        //     })
        //     .catch((error) => {
        //         setLoading(false)
        //         console.error("Error loading image:", error);
        //     });



            const formData = new FormData();

        formData.append('sync', '1');
        // @ts-ignore
        formData.append('image_file', file);

            axios.post('https://techhk.aoscdn.com/api/tasks/visual/scale', formData, {
                headers: {
                    'X-API-KEY': 'wxvhltjkbwvdp8gp5'
                }
            })
                .then(response => {
                    // Handle the response from PicWish
                    console.log(response.data);
                    setAiImage(response.data.data.image)
                    setLoading(false)
                    getAIImageData(response.data.data.image)
                        .then(async (imageData:any) => {
                            // Process the image data as needed
                            const blur = isImageBlurred(imageData, 100);
                            const sharpness = calculateSharpness(imageData);
                            const imageQualityResult = await calculateImageQuality(imageData);
                            // @ts-ignore
                            setAiImageQuality({
                                ...imageQuality, ...imageQualityResult,
                                sharpness,
                                blur,
                                width: imageData.width,
                                height: imageData.height
                            })
                        })
                        .catch((error) => {
                            console.error("Error loading image:", error);
                            setLoading(false)
                        });
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    setLoading(false);
                }).then(()=>setLoading(false));
    };


    // @ts-ignore
    return (
<PageContainer>
<div className={classes.unknownScreen}>
    <div className={classes.wrapper}>
        <div className={classes.buttonWrapp}>
                <Button
                    variant="contained"
                    color="secondary"
                    component="label"
                >
                    Chose image
                    <input
                        accept="image/*"
                        name="image"
                        type="file"
                        onChange={handleFileInputChange}
                        hidden
                    />
                </Button>
        {/*{(image !== null) &&*/}
        {/*    <Button*/}
        {/*        variant="contained"*/}
        {/*        color="primary"*/}
        {/*        component="label"*/}
        {/*        onClick={()=>processImage()}*/}
        {/*    >*/}
        {/*        render AI image*/}
        {/*    </Button>*/}
        {/*}*/}
        </div>

        {/*{loading && <p>loading.....</p>}*/}
        {loading && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}

        {/*<div className={classes.label}> Sorry, Page Not Found</div>*/}
        {/*<div className={classes.link} onClick={()=>navigator('/')}> Back to Home Page</div>*/}
        {imageQuality &&
            <div className={classes.bodyWrap}>
                <div>
                    <div className={classes.imageInfo}>
                            <div className={classes.qulityWrapp}>
                                <div style={{marginRight: "10px"}}>10Inch</div>
                                <div className={classes.qualityRow}>
                                    <div className={classes.qualityRed}></div>
                                    <div className={classes.qualityYelow}></div>
                                    <div className={classes.qualityGreen}></div>
                                    <div className={classes.qualityIndefy} style={{left: `${33.3*fullImageRating?.imageValue10 - 16}%`}}></div>
                                </div>
                            </div>
                        <div className={classes.qulityWrapp}>
                            <div style={{marginRight: "10px"}}>16Inch</div>
                            <div className={classes.qualityRow}>
                                <div className={classes.qualityRed}></div>
                                <div className={classes.qualityYelow}></div>
                                <div className={classes.qualityGreen}></div>
                                <div className={classes.qualityIndefy} style={{left: `${33.3*fullImageRating?.imageValue16 - 16}%`}}></div>
                            </div>
                        </div>
                        <div className={classes.qulityWrapp}>
                            <div style={{marginRight: "10px"}}>24Inch</div>
                            <div className={classes.qualityRow}>
                                <div className={classes.qualityRed}></div>
                                <div className={classes.qualityYelow}></div>
                                <div className={classes.qualityGreen}></div>
                                <div className={classes.qualityIndefy} style={{left: `${33.3*fullImageRating?.imageValue24 - 17}%`}}></div>
                            </div>
                        </div>
                        <p>Size: {imageQuality?.width}X{imageQuality?.height} </p>
                        <p>Resolution: 24inch - {imageQuality?.resolution24}; 16inch - {imageQuality?.resolution16}; 10inch - {imageQuality?.resolution10}.</p>
                        <p>Blur LVL: {imageQuality?.blur}</p>
                        <p>Sharpness LVL: {imageQuality?.sharpness}</p>
                        <p>Noise LVL: {imageQuality?.noise}</p>
                    </div>
                    <div className={classes.imageWrapp}> {image && <img src={image}/>}</div>
                </div>
                {pillowQuality &&
                    <div>
                        <div className={classes.imageInfo}>
                            <div className={classes.qulityWrapp}>
                                <div style={{marginRight: "10px"}}>10Inch</div>
                                <div className={classes.qualityRow}>
                                    <div className={classes.qualityRed}></div>
                                    <div className={classes.qualityYelow}></div>
                                    <div className={classes.qualityGreen}></div>
                                    <div className={classes.qualityIndefy} style={{left: `${33.3*pillowRating?.pillowValue10 - 16}%`}}></div>
                                </div>
                            </div>
                            <div className={classes.qulityWrapp}>
                                <div style={{marginRight: "10px"}}>16Inch</div>
                                <div className={classes.qualityRow}>
                                    <div className={classes.qualityRed}></div>
                                    <div className={classes.qualityYelow}></div>
                                    <div className={classes.qualityGreen}></div>
                                    <div className={classes.qualityIndefy} style={{left: `${33.3*pillowRating?.pillowValue16 - 16}%`}}></div>
                                </div>
                            </div>
                            <div className={classes.qulityWrapp}>
                                <div style={{marginRight: "10px"}}>24Inch</div>
                                <div className={classes.qualityRow}>
                                    <div className={classes.qualityRed}></div>
                                    <div className={classes.qualityYelow}></div>
                                    <div className={classes.qualityGreen}></div>
                                    <div className={classes.qualityIndefy} style={{left: `${33.3*pillowRating?.pillowValue24 - 16}%`}}></div>
                                </div>
                            </div>
                            <p>Size: {pillowQuality?.width}X{pillowQuality?.height} </p>
                            <p>Resolution: 24inch - {pillowQuality?.resolution24}; 16inch - {pillowQuality?.resolution16}; 10inch - {pillowQuality?.resolution10}.</p>
                            <p>Blur LVL: {pillowQuality?.blurPillow}</p>
                            <p>Sharpness LVL: {pillowQuality?.sharpnessPillow}</p>
                            <p>Noise LVL: {pillowQuality?.noise}</p>
                        </div>
                        <div className={classes.imageWrapp}> {pilow && <img src={pilow}/>}</div>
                    </div>
                }
                {aiImage &&
                    <div>
                        {aiImageQuality ?
                            <div className={classes.imageInfo}>
                                <p>Size: {aiImageQuality?.width}X{aiImageQuality?.height} </p>
                                <p>Resolution: 24inch - {aiImageQuality?.resolution24}; 16inch - {aiImageQuality?.resolution16}; 10inch - {aiImageQuality?.resolution10}.</p>
                                <p>Blur LVL: {aiImageQuality?.blur}</p>
                                <p>Sharpness LVL: {aiImageQuality?.sharpness}</p>
                                <p>Noise LVL: {aiImageQuality?.noise}</p>
                            </div> :
                            <p>calculation...</p>
                        }
                        <div className={classes.imageWrapp}> {aiImage && <img src={aiImage}/>}</div>
                    </div>
                }
            </div>
            }
    </div>

</div>
</PageContainer>
  );
};

export default ImageQuality;
