import {createSlice} from '@reduxjs/toolkit';
import {IOneOrder} from '../../types'
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/customerHistory';

const CustomerHistorySlice = createSlice({
    name: "customerHistory",
    initialState: {
        data: {},
        status: null,
        error: null,
    },
    reducers,
    extraReducers
});
const {actions, reducer} = CustomerHistorySlice;
export const {setCustomerHistory, clearFilters} = actions;
export {reducer};
