import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  unknownScreen: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    minHeight: '80vh',
    flexDirection: 'column',
    padding: '0 40px',
  },
  error: {
    fontSize: '50px',
    color: 'grey',
    paddingBottom: '50px'
  },
  label: {
    fontSize: '40px',
    maxWidth: '600px',
    color: '#047d95',
    paddingBottom: '40px',
    fontWeight: 600,
    textAlign: "center",
  },
  label2: {
    fontSize: '24px',
    maxWidth: '600px',
    color: 'black',
    paddingBottom: '20px',
    fontWeight: 600,
    textAlign: "center",
  },
});

export default useStyles;
