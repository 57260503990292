import React, {useEffect} from 'react';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import PageContainer from '../../../components/PageContainer';
import {IStory, IStore, IOneStory} from "../../../store/types";
import {Stories} from '../../../store/slices'

import AuthorForm from "../../../components/AuthorForm";
import {Paper} from "@mui/material";
import StoryForm from "../../../components/StoryForm";
import GiftCadsForm from "../../../components/GiftCardsForm";

/** Components */
export type TOrderProps = {
    id: number
}
const getID = (id) => {
    return `a${id}a`
}

const OneStory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const story = useSelector<IStore, any>((state) => {
      return {data: { ...state.stories.data[getID(id) || '']}, status: state.stories.status }});

  useEffect(() => {
        dispatch<any>(Stories.getStory(id || ''));
  }, [])

  return (
      <PageContainer>
          <h2 style={{marginTop: 0, color:'#047d95', marginBottom: '40px', fontWeight: 600}}>Story Id : {id}</h2>
          <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px 10px', maxHeight: '80vh', overflowY: 'scroll' }}>
              {Object.keys(story.data).length !== 0 && <>
                  <AuthorForm data={story} />
                  <StoryForm data={story} />
                  <GiftCadsForm data={story} />
              </>}
          </Paper>
      </PageContainer>
  );
};

export default OneStory;
