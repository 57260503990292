import {createSlice} from '@reduxjs/toolkit';
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/auth';

const usersSlice = createSlice({
    name: "auth",
    initialState: {
        data: <Record<string, any>>{},
        status: null,
        error: null
    },
    reducers,
    extraReducers
});
const {actions, reducer} = usersSlice
export const {setAuthenticatedUser} = actions;
export {reducer};
export const selectToken = (state) => state.auth.data.token;
