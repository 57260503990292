import * as React from 'react';

import { useStyles } from './styles';
import {Box, TextField} from "@mui/material";
import {AAV_LINK} from '../../constants'



export default function AuthorForm(props: any) {

    const { data } = props.data;
    const classes = useStyles();

    function formatString(str) {
        // Remove leading and trailing spaces
        str = str.trim();

        // Replace spaces between words with "-"
        str = str.replace(/\s+/g, "-");

        return str;
    }

    return (
        <div className={classes.authorWrap}>
            <div className={classes.authorHeader}><span>Author</span></div>
            {data.isPublished === 1 && <div className={classes.storyLink}><a target="_blank" href={`${AAV_LINK}pages/${formatString(data.firstName)}-${data.storyId}`}>Story Link</a></div> }
            <div className={classes.inputWrap}>
                <TextField label="Name" variant="outlined" disabled InputProps={{
                    readOnly: true,
                }} value= {data.firstName || ''} sx={{ m: 1, width: '30ch'}}/>
                <TextField label="Email" variant="outlined" InputProps={{
                    readOnly: true,
                }} value= {data.email || ''} disabled sx={{ m: 1, width: '30ch'}}/>
                <TextField label="Author ID" variant="outlined" InputProps={{
                    readOnly: true,
                }} value= {data.authorId || ''} disabled sx={{ m: 1, width: '30ch'}}/>
            </div>
            <div className={classes.inputWrap}>
                <TextField label="Shopify customer ID" variant="outlined" InputProps={{
                    readOnly: true,
                }} value= {data.shopifyId || ''} disabled sx={{ m: 1, width: '30ch'}}/>
                <TextField label="Referral-link" variant="outlined" InputProps={{
                    readOnly: true,
                }} value= {data.refferalLink || ''} disabled sx={{ m: 1, width: '30ch'}}/>
                <TextField label="Rewards" variant="outlined" InputProps={{
                    readOnly: true,
                }} value= {data.rewards || ''} disabled sx={{ m: 1, width: '30ch'}}/>
            </div>
        </div>
    );
}