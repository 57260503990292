export const API_URL = process.env.REACT_APP_REQUEST_URL;
export const API_URL_NEW = process.env.REACT_APP_REQUEST_URL_NEW;
export const API_URL_INTEGRATION = process.env.REACT_APP_REQUEST_URL;
export const AAV_LINK = process.env.REACT_APP_AAV_LINK;
export const API_URL_STORY = process.env.REACT_APP_STORY_LINK;

export const ORDER_API_URL = process.env.REACT_APP_ORDER_API_URL;
export const PRINTFULL_ID = process.env.REACT_APP_PRINTFULL_ID;
export const FREEPRINTS_ID = process.env.REACT_APP_FREEPRINTS_ID;
export const INK_ID = process.env.REACT_APP_INK_ID;


// REACT_APP_API_URL: 'https://order-api.aav-services.com/admin'
// REACT_APP_PRINTFULL_ID:'27768'
// REACT_APP_FREEPRINTS_ID:'47693'