import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';

/** Components */
import Skeleton from '@mui/material/Skeleton';
import PageContainer from '../../components/PageContainer';
/** Selectors */
import { Analytics3d, CustomerHistory }  from '../../store/slices'
import {IStore} from "../../store/types";
import Box from '@mui/material/Box';
import MockupHistoryFilters from "../../components/MockupHistoryFilters";
import Dayjs from "dayjs";
import {styled} from "@mui/material/styles";
import Tooltip, {tooltipClasses, TooltipProps} from "@mui/material/Tooltip";

const DEFAULT_PAGING_STATE = {
    limit: 50,
    maxDate: Dayjs(new Date()).format("YYYY-MM-DD"),
    minDate: Dayjs(new Date()).format("YYYY-MM-DD"),
    orderExist: 'all',
};

const columnsValues = [
    2,3,4,5,6
];

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        maxWidth: 400,
        color: 'black',
        border: '1px solid #dadde9',
    },
}));

const MockupsPage = () => {
    const {
        analytics3d,
        status,
        queryParams
    } = useSelector<IStore, { analytics3d: any, status: string , queryParams: any}>(state => ({
        analytics3d: state.analytics3d.mockups,
        status: state.analytics3d.status,
        queryParams: state.analytics3d.mockups.queryParams
    }));
    const {
        customerHistory,
        customerStatus,
    } = useSelector<IStore, { customerHistory: any, customerStatus: string}>(state => ({
        customerHistory: state.customerHistory.data,
        customerStatus: state.customerHistory.status,
    }));

    const dispatch = useDispatch();
    const blockRef = useRef(null);
    const [columnsValue, setColumnsValue] = React.useState(4);
    const classes = useStyles({columnsValue});
    const [popup, setPopup] = useState<JSX.Element | null>(null);
    const [currentCustomerId, setCurrentCustomerId] = useState<string | null>(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customerId = params.get('customerId');

    const handleChangeColumns = (event: React.SyntheticEvent, newValue: number) => {
        setColumnsValue(newValue);
    };

    const loadOrders = (queryParams) => {
        dispatch<any>(Analytics3d.getMockups3d(queryParams));
    };

    const onLoadMore = (queryParams) => {
        dispatch<any>(Analytics3d.addMockups3d({...queryParams, lastEvaluatedKey: analytics3d.lastEvaluatedKey}));
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Викликаємо функцію дозавантаження
                    onLoadMore(queryParams);
                }
            },
            { threshold: .01 } // Відсоток видимості (100%)
        );

        if (blockRef.current) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current) {
                observer.unobserve(blockRef.current);
            }
        };
    }, [onLoadMore]);

    const showPopup = (id) => {
        setCurrentCustomerId(id);  // Оновлюємо стан для id
        dispatch<any>(CustomerHistory.getCustomerHistory(id));
        window.history.pushState(
            {},
            '',
            `?customerId=${id}`
        );
        if(customerHistory[id]){
            const minDate = Dayjs(new Date(queryParams?.minDate)).format("YYYY-MM-DD");
            const maxDate = Dayjs(new Date(queryParams?.maxDate)).format("YYYY-MM-DD");


            const filteredData = [...customerHistory[id].filter(item => {
                const itemDate = Dayjs(new Date(item.timestamp)).format("YYYY-MM-DD");
                if (itemDate >= minDate && itemDate <= maxDate) {
                    return item;
                }
            })].reverse();

            const history = [...customerHistory[id]].reverse();
            const closePopup = () => {
                window.history.replaceState({}, '', window.location.pathname);
                setPopup(null);
            }
            setPopup(
                <div className={classes.popUp}>
                    <div className={classes.popUpWrap}>
                        <div className={classes.loader}></div>
                        <div onClick={() => closePopup()} className={classes.popUpClose}>X</div>
                        <h2 style={{textAlign: 'center'}}>Customer Upload History</h2>
                        <div className={classes.popUpShort}>
                            <span>Total Uploads : {history.length}</span>
                            <span>In Cart : {history.filter(item=> item.toCart).length}</span>
                            <span>Ordered : {history.filter(item=> item.orderExist).length}</span>
                        </div>
                        <div className={classes.contentWrapper}>
                            {filteredData.map((element, index) => (
                                <div className={classes.popUpBox} key={index}>
                                    <div style={{width: '33%', textAlign: 'center'}}>
                                        <div>Upload #<span>{index + 1}</span></div>
                                        <div>{new Date(element.timestamp).toLocaleString()}</div>
                                        <div>Process Duration - <span>{element?.processDuration}s</span></div>
                                        <div>Was added to Cart: <span>{element.toCart ? 'YES' : 'NO'}</span></div>
                                        <div>Was ordered: <span>{element.orderExist ? 'YES' : 'NO'}</span></div>
                                        {element.orderExist && <div>
                                            <div>Order ID: {element.orderId}</div>
                                            <div>Order Number: {element.orderNumber}</div>
                                            <div>Order Date: {new Date(element.orderDate).toLocaleString()}</div>
                                        </div>}
                                    </div>
                                    {element.fileUrl && element.fileUrl !== 'none' ? (
                                        <img loading="lazy" src={element.fileUrl} alt="File" />
                                    ) :
                                         element.saveUrl ? <img loading="lazy" src={element.saveUrl} alt="File" />: <div>NOT FOUND</div>
                                    }
                                    {element.mockupUrl ? (
                                        <img loading="lazy" src={element.mockupUrl} alt="Mockup" />
                                    ) : (
                                        <div style={{width: '33%'}}>
                                            <div>NOT FOUND</div>
                                            {element.errorMessage &&
                                                <div>
                                                    Error:<br/>
                                                    {element.errorMessage}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            ))}
                            {filteredData.length != history.length &&
                            <div>
                                <h2 style={{textAlign: 'center', borderBottom: '2px solid #047d95'}}>All history</h2>
                            {history.map((element, index) => (
                                <div className={classes.popUpBox} key={index}>
                                    <div style={{width: '33%', textAlign: 'center'}}>
                                        <div>Upload #<span>{index + 1}</span></div>
                                        <div>{new Date(element.timestamp).toLocaleString()}</div>
                                        <div>Process Duration - <span>{element?.processDuration}s</span></div>
                                        <div>Was added to Cart: <span>{element.toCart ? 'YES' : 'NO'}</span></div>
                                        <div>Was ordered: <span>{element.orderExist ? 'YES' : 'NO'}</span></div>
                                        {element.orderExist && <div>
                                            <div>Order ID: {element.orderId}</div>
                                            <div>Order Number: {element.orderNumber}</div>
                                            <div>Order Date: {new Date(element.orderDate).toLocaleString()}</div>
                                        </div>}
                                    </div>
                                    {element.fileUrl && element.fileUrl !== 'none' ? (
                                            <img loading="lazy" src={element.fileUrl} alt="File" />
                                        ) :
                                        element.saveUrl ? <img loading="lazy" src={element.saveUrl} alt="File" />: <div>NOT FOUND</div>
                                    }
                                    {element.mockupUrl ? (
                                        <img loading="lazy" src={element.mockupUrl} alt="Mockup" />
                                    ) : (
                                        <div style={{width: '33%'}}>
                                            <div>NOT FOUND</div>
                                            {element.errorMessage &&
                                                <div>
                                                    Error:<br/>
                                                    {element.errorMessage}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            ))}
                            </div>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            setPopup(
                <div className={classes.popUp}>
                    <div className={classes.popUpWrap} style={{height: 620}}>
                        <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />
                    </div>
                </div>
            )
        }
    };

    // Використовуємо useEffect для оновлення popup після зміни customerHistory
    useEffect(() => {
        if (currentCustomerId && customerHistory[currentCustomerId]) {
            const minDate = Dayjs(new Date(queryParams?.minDate)).format("YYYY-MM-DD");
            const maxDate = Dayjs(new Date(queryParams?.maxDate)).format("YYYY-MM-DD");


            const filteredData = [...customerHistory[currentCustomerId].filter(item => {
                const itemDate = Dayjs(new Date(item.timestamp)).format("YYYY-MM-DD");
                if (itemDate >= minDate && itemDate <= maxDate) {
                    return item;
                }
            })].reverse();

            const closePopup = () => {
                window.history.replaceState({}, '', window.location.pathname);
                setPopup(null);
            }
            const history = [...customerHistory[currentCustomerId]].reverse();
            setPopup(
                <div className={classes.popUp}>
                    <div className={classes.popUpWrap}>
                        <div onClick={() => closePopup()} className={classes.popUpClose}>X</div>
                        <h2 style={{textAlign: 'center'}}>Customer Upload History</h2>
                        <div className={classes.popUpShort}>
                            <span>Total Uploads : {history.length}</span>
                            <span>In Cart : {history.filter(item=> item.toCart).length}</span>
                            <span>Ordered : {history.filter(item=> item.orderExist).length}</span>
                        </div>
                        <div className={classes.contentWrapper}>
                            <div style={{display:'flex', textAlign:"center", width: '100%', justifyContent:'space-around', padding: '10px',
                            backgroundColor: '#d6d6d6',
                            borderRadius: '10px',
                                margin: '10px 0'
                            }}>
                                <div>CustomerId: <br/>{history[0]?.customerId}</div>
                                <div>City/Country: <br/>{history[0]?.city}/{history[0]?.country}</div>
                                <div>Device/Os: <br/>{history[0]?.device}/{history[0]?.os}</div>
                                <div>Browser: <br/>{history[0]?.browser}</div>
                            </div>
                            {filteredData.map((element, index) => (
                                <div className={classes.popUpBox} key={index}>
                                    <div style={{width: '33%', textAlign: 'center'}}>
                                        <div>Upload #<span>{index + 1}</span></div>
                                        <div>{new Date(element.timestamp).toLocaleString()}</div>
                                        <div>Process Duration - <span>{element?.processDuration}s</span></div>
                                        <div>Was added to Cart: <span>{element.toCart ? 'YES' : 'NO'}</span></div>
                                        <div>Was ordered: <span>{element.orderExist ? 'YES' : 'NO'}</span></div>
                                        {element.orderExist && <div>
                                            <div>Order ID: {element.orderId}</div>
                                            <div>Order Number: {element.orderNumber}</div>
                                            <div>Order Date: {new Date(element.orderDate).toLocaleString()}</div>
                                        </div>}
                                    </div>
                                    {element.fileUrl && element.fileUrl !== 'none' ? (
                                            <img loading="lazy" src={element.fileUrl} alt="File" />
                                        ) :
                                        element.saveUrl ? <img loading="lazy" src={element.saveUrl} alt="File" />: <div>NOT FOUND</div>
                                    }
                                    {element.mockupUrl ? (
                                        <img loading="lazy" src={element.mockupUrl} alt="Mockup" />
                                    ) : (
                                        <div style={{width: '33%'}}>
                                            <div>NOT FOUND</div>
                                            {element.errorMessage &&
                                                <div>
                                                    Error:<br/>
                                                    {element.errorMessage}
                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            ))}
                            {filteredData.length != history.length &&
                                <div>
                                    <h2 style={{textAlign: 'center', borderBottom: '2px solid #047d95'}}>All history</h2>
                                    {history.map((element, index) => (
                                        <div className={classes.popUpBox} key={index}>
                                            <div style={{width: '33%', textAlign: 'center'}}>
                                                <div>Upload #<span>{index + 1}</span></div>
                                                <div>{new Date(element.timestamp).toLocaleString()}</div>
                                                <div>Process Duration - <span>{element?.processDuration}s</span></div>
                                                <div>Was added to Cart: <span>{element.toCart ? 'YES' : 'NO'}</span></div>
                                                <div>Was ordered: <span>{element.orderExist ? 'YES' : 'NO'}</span></div>
                                                {element.orderExist && <div>
                                                    <div>Order ID: {element.orderId}</div>
                                                    <div>Order Number: {element.orderNumber}</div>
                                                    <div>Order Date: {new Date(element.orderDate).toLocaleString()}</div>
                                                </div>}
                                            </div>
                                            {element.fileUrl && element.fileUrl !== 'none' ? (
                                                    <img loading="lazy" src={element.fileUrl} alt="File" />
                                                ) :
                                                element.saveUrl ? <img loading="lazy" src={element.saveUrl} alt="File" />: <div style={{minWidth: '33%'}}>NOT FOUND</div>
                                            }
                                            {element.mockupUrl ? (
                                                <img loading="lazy" src={element.mockupUrl} alt="Mockup" />
                                            ) : (
                                                <div style={{width: '33%'}}>
                                                    <div>NOT FOUND</div>
                                                    {element.errorMessage &&
                                                        <div>
                                                            Error:<br/>
                                                            {element.errorMessage}
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }, [customerHistory, currentCustomerId]); // Перезапускати ефект при зміні customerHistory або currentCustomerId
    useEffect(() => {
    if(customerId && customerId !== currentCustomerId ){
        console.log(customerId,'-----idCustomer');
        // setCurrentCustomerId(customerId);
        showPopup(customerId);
    }
    },[])


    useEffect(() => {
        loadOrders({
            ...queryParams,
            limit: queryParams?.limit || DEFAULT_PAGING_STATE.limit,
            minDate: queryParams?.minDate || DEFAULT_PAGING_STATE.minDate,
            maxDate: queryParams?.maxDate || DEFAULT_PAGING_STATE.maxDate,
            orderExist: queryParams?.orderExist || DEFAULT_PAGING_STATE.orderExist,
        });
    }, [])

    return (
        <PageContainer>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                <h2 style={{marginTop: 0, color:'#047d95', fontWeight: 600}}>3D-Mockups</h2>
            </div>
            {popup}
            <MockupHistoryFilters queryParams={{...DEFAULT_PAGING_STATE, ...queryParams}} loadMockups={loadOrders} loading={status === 'loading'} columCount={setColumnsValue}/>
            {(analytics3d?.result && status !== 'loading') &&
                <Box sx={{ width: '100%', typography: 'body1', maxHeight: '70vh', overflow: 'scroll', border: '1px solid #dddddd', borderRadius: '10px' }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', padding: '10px'}}>
                        {analytics3d?.result &&
                            analytics3d.result.map((event) => (
                                <div className={classes.blockWrapper} key={event.id}>
                                    {event?.mockupUrl ? (
                                        <img loading="lazy" style={{maxHeight: '100%', maxWidth: '100%', width: 'auto'}} src={event.mockupUrl} alt="Mockup"/>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: "column"}}>
                                            <div>Upload with issue</div>
                                            <div>Event ID :</div>
                                            <div>{event.id}</div>
                                        </div>
                                    )}
                                    <div className={classes.itemNav}>
                                        {event.toCart && <div className={classes.addedToCart}>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        {`Added to cart: ${new Date(event.toCart).toLocaleString()}`}
                                                    </React.Fragment>
                                                }
                                            >
                                               <span>✔</span>
                                            </HtmlTooltip>
                                        </div>}
                                        {event.orderDate && <div className={classes.ordered}>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        {`Ordered: ${new Date(event.orderDate).toLocaleString()}`}
                                                    </React.Fragment>
                                                }
                                            >
                                                <span>$</span>
                                            </HtmlTooltip>
                                            </div>}
                                    </div>
                                    <div className={classes.itemNavBottom}>
                                        {event.additionalEvents &&
                                            <div className={classes.editButton} onClick={() => {showPopup(event.customerId)}}>History</div>
                                        }
                                    </div>
                                </div>
                            ))
                        }
                        {analytics3d?.lastEvaluatedKey &&
                            <div className={classes.blockWrapper} ref={blockRef}>
                                <Skeleton animation="wave" width={'100%'} height={'98%'} variant="rounded" />
                            </div>
                        }
                    </div>
                    {analytics3d?.result.length < 1 && status !== 'loading' && <div style={{margin: 20}}>Don't found result</div>}
                </Box>
            }
            {status === 'loading' &&
                <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
        </PageContainer>
    );
};

export default MockupsPage;
