import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    width: 287,
    boxShadow: [0, 2, 13, 'rgba(0, 0, 0, 0.065696)'],
    borderRadius: 8,
  },
  itemsWrapper:{

  },
  itemIcon: {
    marginRight: 28,
    marginLeft: 25,
    display: 'flex',
    alignItems: 'center',
  },
  itemTitle: {
    lineHeight: '75px',
    fontSize: '18px',
    fontWeight: 600,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#666666',
    '&:hover $linkConetnt': {
      textDecoration: 'underline',
      backgroundColor: '#F6F7F8',
      borderLeft: [5, 'solid', '#047d95'],
      '& div:first-child': {
        marginLeft: 30,
      },
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  linkConetnt: {
    display: 'flex',
  },
  activeRoute: {
    cursor: 'initial',
    '& $linkConetnt': {
      backgroundColor: '#F6F7F8',
      borderLeft: [5, 'solid', '#047d95'],
      '& div:first-child': {
        marginLeft: 30,
      },
    },
  },
  subNavigation: {
    marginLeft: 83,
    '& $itemTitle': {
      margin: [20, 0],
      lineHeight: '30px',
      '&:hover': {
        borderLeft: [5, 'solid', 'rgb(255 165 0 / 50%)'],
        paddingLeft: 5,
        textDecoration: 'underline',
      },
    },
    '& $activeRoute $itemTitle': {
      borderLeft: [5, 'solid', 'rgb(255 165 0 / 50%)'],
      paddingLeft: 5,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  arrowDown: {
    display: 'flex',
    alignItems: 'center',
    margin: '25px',
    width: '100%',
    justifyContent: 'end',
    cursor: 'pointer',
  },
  subOpen: {
    '& svg': {
      transform: 'rotate(180deg)'
    }
  },
  customAcordion: {
    '&.Mui-expanded':{
      margin: '1px 0'
    },
    '& .MuiAccordionDetails-root':{
      padding: '8px 0px 16px',
    }
  }
});
