import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    editButton: {
        padding:[15, 30],
        width: 'fit-content',
        margin: '0 auto',
        backgroundColor: '#047d95',
        color: 'white',
        cursor:'pointer',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#005566',
        }
    },
    filtersWrap: {
        marginBottom: '25px'
    },
    popUpWrapper: {
        width: '100%',
        height: '100%',
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#808080c2",
        top: 0,
        left: 0,
        zIndex: 20,
    },
    popUp: {
        maxWidth: '700px',
        maxHeight: '600px',
        backgroundColor: 'white',
        padding: '40px 50px',
        borderRadius: '20px',
        position: "relative",
    },
    popUpImage:{
        width: '100%',
        maxHeight: '600px',
        marginBottom: '40px',
        '& img': {
            display: "block",
            maxWidth: '100%',
            maxHeight: '400px',
            margin: '0 auto',
        }
    },
    buttonsWrap: {
        display: "flex",
        minWidth: '450px',
        justifyContent: 'space-around'
    },
    popUpLoader: {
        width: '100%',
        height: '100%',
        backgroundColor: '#80808080',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        borderRadius: '20px',
    }
})