import {getAllAuthors, getAuthorById} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../store";
import {setNotifications} from "../slices/notifications";

export const getAuthors = createAsyncThunk(
    'authors/getAuthors',
    async (queryParams, {rejectWithValue}) => {
        try {
            const authors = await getAllAuthors(queryParams);
            return authors;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)

export const getAuthor = createAsyncThunk(
    'authors/getAuthor',
    async (id: string, {rejectWithValue}) => {
        try {
            const author = await getAuthorById(id);
            return author;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)

