import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { useStyles } from './styles';
import {Auth, Stories, Notifications} from "../../store/slices";
import {Box, Button, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {createGiftCard, disableGiftCard} from "../../api";
import {useDispatch} from "react-redux";
import Skeleton from "@mui/material/Skeleton";

const GiftCartStatus = {
    1:{title: 'Created', value: 1},
    2:{title: 'Sent', value: 2},
    3:{title: 'Disabled', value: 3},
    4:{title: 'Error', value: 4},
}

const DEFAULT_PAGING_STATE = {
    // perPage: 10,
    page: 1,
    total: 0
};


export default function GiftCadsForm(props: any) {
    const { data, status } = props.data;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [inputValue, setInputValue] = useState<string>('')
    const [pending, setPending] = useState(false);
    const [paging, setPaging ] = useState(DEFAULT_PAGING_STATE)
    const createGiftCart = async () => {
        setPending(true);
        try {
            await createGiftCard({
                data: {
                    gfStoryId: data.storyId,
                    gfAuthorId: data.authorId,
                    amount: +inputValue,
                },
                onSuccess: async () => {
                    await loadStoryGiftCards();
                    dispatch(Notifications.setNotifications({text: "Gift card created successful", type: 'success'}))
                }
            });
        } catch (error) {
            dispatch(Notifications.setNotifications({text: 'Something went wrong, please try again later', type: 'error'}))
        } finally {
            setPending(false);
        }
    };
    const disableGiftCart =  async (id) =>{
        setPending(true);
        try {
             await disableGiftCard({
                id:id,
                onSuccess: async () => {
                    await loadStoryGiftCards();
                    dispatch(Notifications.setNotifications({text: "Gift card disabled successful", type: 'success'}))
                }});
        } catch (error) {
            dispatch(Notifications.setNotifications({text: 'Something went wrong, please try again later', type: 'error'}))
        } finally {
            setPending(false);
        }
    }

    const loadStoryGiftCards = (queryParams?) => {
        dispatch<any>(Stories.getStoryGiftCarts({id: data.storyId || 0, queryParams: {
                page: queryParams?.page || DEFAULT_PAGING_STATE.page,
                // perPage: queryParams?.perPage || DEFAULT_PAGING_STATE.perPage,
            }}))
    }

    const handleChangePage = (event: unknown, newPage: number) => {
           setPaging({...paging, page: newPage+1})
           loadStoryGiftCards({...paging, page: newPage+1})
    };


    useEffect(()=> {
        loadStoryGiftCards({
            page: DEFAULT_PAGING_STATE.page,
            // perPage: DEFAULT_PAGING_STATE.perPage,
        })
    },[])
    return (
        <div className={classes.authorWrap}>
            {(pending || status == 'loading') && <div className={classes.giftCardLoader}> <Skeleton animation="wave" width={'100%'} height={'100%'} variant="rounded" /></div>}
            <div className={classes.authorHeader}><span>Gift Cards</span></div>
            <div className={classes.inputWrap}>
                <TextField label="Amount" type="number" inputProps={{ inputMode: 'numeric', pattern: '^[1-9]\\d*$' }} variant="outlined" sx={{ m: 1, width: '30ch'}} onChange={(e)=>setInputValue(e.target.value)}/>
                <Button onClick={createGiftCart} variant="contained" sx={{m:1}}> create</Button>
            </div>
            {data.giftCards && data?.giftCards.length > 0 && <div className={classes.giftCardTable}>
                <Table sx={{  }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Gift Card ID</b></TableCell>
                            <TableCell align="right"><b>Amount</b></TableCell>
                            <TableCell align="right"><b> Gift Card status</b></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.giftCards.map((row) => (
                            <TableRow
                                key={row.giftcardId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.giftcardId}
                                </TableCell>
                                <TableCell align="right">{row.amount}</TableCell>
                                <TableCell align="right">{GiftCartStatus[row.giftcardStatusId].title}</TableCell>
                                <TableCell align="right">
                                        <Button variant="contained" disabled={row.giftcardStatusId === 3} onClick={()=>disableGiftCart(row.giftcardId)}>disable</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={ data.giftCardsTotal || paging.total}
                    rowsPerPage={10}
                    page={paging.page-1}
                    onPageChange={handleChangePage}
                />
            </div>}
        </div>
    );
}