import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Button, Checkbox, CircularProgress, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import  Dayjs  from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Notifications, Orders} from "../../store/slices";
import debounce from 'lodash/debounce';
import * as yup from "yup";
import {useParams} from "react-router-dom";
import {disableGiftCard, getReportModerator} from "../../api";

const OrderStatus = [
    {status: 'Created', color:'#00ccff54', value: 1},
    {status: 'Production', color:'#ffa500ab', value: 2},
    {status: 'Shipped', color:'#4bd30899', value: 3},
    {status: 'Partial Shipped', color:'#8ffa98', value: 7},
    {status: 'Canceled', color:'#80808073', value: 5},
    {status: 'Error', color:'#ff000073', value: 6},
    {status: 'Hold', color:'rgba(224,0,255,0.45)', value: 8},
]

const DEFAULT_FILTERS = {
    sort: 'createdAt',
    sortDirection:'DESC',
    // createdAt_max: Dayjs(new Date()).format("YYYY-MM-DD")
};

const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]

export default function OrdersReport(props: any) {
    const { queryParams, loadOrders, loading, isPopUp, popUpHandler } = props;
    const [reportFilters, setReportFilters] = useState<any>({...DEFAULT_FILTERS});
    const dataLink = useRef(null);
    const [data, setData] = useState();

    const dispatch = useDispatch();
    const { id } = useParams();
    console.log(id, 'companyID');
    const classes = useStyles();
    const [pending, setPending] = useState(false);


    console.log(queryParams, 'LOADED PARAMS')
    const handleFilterChange = (e) => {
        console.log(e)
        debugger;
        const { name, value } = e.target;
        const newQueryParams = {
            ...reportFilters,
            [name]: value,
        }
        if(name === 'createdAt_max' && !newQueryParams.createdAt_min){
            newQueryParams.createdAt_min = '2024-01-01'
        }
        if(name === 'createdAt_min' && !newQueryParams.createdAt_max){
            newQueryParams.createdAt_max = Dayjs(new Date()).format("YYYY-MM-DD")
        }

        console.log('PARAMS', newQueryParams)
        setReportFilters(newQueryParams);
    }


    const getReport = async () => {
        setPending(true);
        try {
            await getReportModerator({
                companyId: id,
                queryParams: {...reportFilters},
                onSuccess: async (data) => {
                    console.log(data.downloadUrl);
                    setData(data.downloadUrl);
                    dispatch(Notifications.setNotifications({text: "Download started", type: 'success'}))
                    setReportFilters({...DEFAULT_FILTERS});
                    popUpHandler(true);
                }});
        } catch (error) {
            dispatch(Notifications.setNotifications({text: 'Something went wrong, please try again later', type: 'error'}))
        } finally {
            setPending(false);
        }
    }
    useEffect(() => {
        if (data) {
            // @ts-ignore
            dataLink.current.click();
        }
    }, [data]);
    // @ts-ignore
    return (
        <>
            {isPopUp &&
            <div className={classes.popUpWrapper}>
                <a className="hidden-element" download href={data} ref={dataLink} rel={'nofollow'} target={'_blank'}></a>
                <div className={classes.popUp}>
                    {pending && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}
                    <div className={classes.popUpImage}>
                        <div className={classes.filtersWrap}>
                            <FormControl sx={{ m: 1,  width: '17ch' }} >
                                <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                                <Select
                                    id="demo-simple-select-helper"
                                    defaultValue={[]}
                                    // value={queryParams?.orderStatusId ? queryParams?.orderStatusId : []}
                                    label="Status"
                                    variant="outlined"
                                    name="orderStatusId"
                                    onChange={(event) =>handleFilterChange(event)}
                                    multiple
                                    disabled={loading}
                                >
                                    {OrderStatus.map((order)=>
                                        <MenuItem key={order.value} value={order.value}> {order.status}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1,  width: '17ch', }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="Min Date"
                                        format="YYYY-MM-DD"
                                        defaultValue={ queryParams?.createdAt_min && queryParams?.createdAt_min !== '2024-01-01' ? Dayjs(queryParams?.createdAt_min) : null }
                                        maxDate={queryParams?.createdAt_max && Dayjs(queryParams?.createdAt_max) || Dayjs(new Date())}
                                        // @ts-ignore
                                        valueType="date"
                                        disabled={loading}
                                        onChange={(newValue) =>handleFilterChange({target:{name: 'createdAt_min', value: newValue.format("YYYY-MM-DD")}})}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl sx={{ m: 1,  width: '17ch', }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="Max Date"
                                        format="YYYY-MM-DD"
                                        defaultValue={queryParams?.createdAt_max && Dayjs(queryParams?.createdAt_max) || Dayjs(new Date())}
                                        maxDate={Dayjs(new Date())}
                                        // @ts-ignore
                                        valueType="date"
                                        disabled={loading}
                                        onChange={(newValue) =>handleFilterChange({target:{name: 'createdAt_max', value: newValue.format("YYYY-MM-DD")}})}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.buttonsWrap}>
                        <Button
                            variant="outlined"
                            onClick={()=>popUpHandler(true)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            onClick={()=>getReport()}
                        >
                            Get CSV File
                        </Button>
                    </div>
                </div>
            </div>
        }
        </>
    );
}