import React from 'react';
import {useNavigate} from "react-router";
import useStyles from './styles';
import Header from "../../components/Header";



const Unknown = () => {
  const classes = useStyles();
    const navigator = useNavigate();
  return (
<div className={classes.unknownScreen}>
           <Header />
    <div className={classes.wrapper}>
        <div className={classes.error}>#ERROR 404</div>
        <div className={classes.label}> Sorry, Page Not Found</div>
        <div className={classes.link} onClick={()=>navigator('/')}> Back to Home Page</div>
    </div>

</div>
  );
};

export default Unknown;
