import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import  Dayjs  from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Notifications, Orders} from "../../store/slices";
import debounce from 'lodash/debounce';
import * as yup from "yup";
import {useParams} from "react-router-dom";
import {
    disableGiftCard,
    getReportModerator,
    setCancelOrder,
    setHoldStatus,
    setUpdateOrder,
    setVoidLabel
} from "../../api";
import {IOneStory} from "../../store/types";

const OrderStatus = [
    {status: 'Created', color:'#00ccff54', value: 1},
    {status: 'Production', color:'#ffa500ab', value: 2},
    {status: 'Shipped', color:'#4bd30899', value: 3},
    {status: 'Partial Shipped', color:'#8ffa98', value: 7},
    {status: 'Error', color:'#ff000073', value: 6},
]


const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]

const ShippingMethodCode = [
    {title: 'USPS Ground Advantage', value: 'USPS Ground Advantage'},
    {title: 'USPS Priority Mail', value: 'USPS Priority Mail'},
    {title: 'UPSMI Parcel Select', value: 'UPSMI Parcel Select'},
]

export default function OrderEditForm(props: any) {
    const { data, isPopUp, popUpHandler } = props;
    const [formData, setFormData] = useState<any>({})
    const [recipient, setRecipientFormData] = useState<any>({})
    const { id, companyId } = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [pending, setPending] = useState(false);

    const getOrder = () => {
        dispatch<any>(Orders.getOrder({id: id || '', companyId: companyId || ''}));
    }

    const updateOrder = async () => {
        setPending(true);
        let data = {...formData}
        if(Object.keys(recipient).length !== 0){
            data = {...data, recipient}
        }
        try {
            await setUpdateOrder({
                id,
                companyId,
                data,
                onSuccess: async (data) => {
                    dispatch(Notifications.setNotifications({text: "Order updated", type: 'success'}))
                    setFormData({});
                    setRecipientFormData({});
                    popUpHandler(true);
                    getOrder();
                }});
        } catch (error) {
            // @ts-ignore
            dispatch(Notifications.setNotifications({text: error.message, type: 'error'}))
        } finally {
            setPending(false);
        }
    }
    // const updateOrder = () => {
    //     console.log({...formData, recipient});
    // }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        // @ts-ignore
        setRecipientFormData({
            ...recipient,
            [name]: value,
        });
    }
    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        // @ts-ignore
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    // @ts-ignore
    return (
        <>
            {isPopUp &&
            <div className={classes.popUpWrapper}>
                <div className={classes.popUp}>
                    {pending && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}
                    <div className={classes.popUpImage}>
                        <h3 style={{width:'100%', margin: '0', textAlign: 'center'}}>Update Order</h3>
                        <div className={classes.inputWrap}>
                            <FormControl sx={{ m: 1,  width: '30ch' }}>
                                <InputLabel id="demo-simple-select-helper-label">Order Status</InputLabel>
                                <Select
                                    label="Order Status"
                                    variant="outlined"
                                    name="orderStatusId"
                                    defaultValue={data.orderStatusId}
                                    disabled={data.onHold}
                                    onChange={(event) =>handleSelectChange(event)}
                                >
                                    {OrderStatus.map((method)=>
                                        <MenuItem key={`${method.value}`} value={method.value}>{method.status}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1,  width: '30ch' }}>
                                <InputLabel id="demo-simple-select-helper-label">Shipping Method Code</InputLabel>
                                <Select
                                    label="Shipping Method Code"
                                    variant="outlined"
                                    name="shippingMethodCode"
                                    defaultValue={data.shippingMethodCode || null}
                                    onChange={(event) =>handleSelectChange(event)}
                                    disabled={companyId === '27768' ? true: false}
                                >
                                    {ShippingMethodCode.map((method)=>
                                        <MenuItem key={`${method.value}`} value={method.value}>{method.title}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <div className={classes.inputWrap}>
                            <TextField
                                label="Name"
                                variant="outlined"
                                name='name'
                                defaultValue= {data.recipient.name || ''}
                                onChange={(e)=>handleInputChange(e)}
                                sx={{ m: 1, width: '30ch'}}/>
                            <TextField
                                label="Country"
                                variant="outlined"
                                name='country'
                                onChange={(e)=>handleInputChange(e)}
                                defaultValue= {data.recipient.country || ''}
                                sx={{ m: 1, width: '30ch'}}/>
                            <TextField
                                label="Postal Code"
                                variant="outlined"
                                name='postalCode'
                                onChange={(e)=>handleInputChange(e)}
                                defaultValue= {data.recipient.postalCode || ''}
                                sx={{ m: 1, width: '30ch'}}/>

                        </div>
                        <div className={classes.inputWrap}>
                            <TextField
                                label="State"
                                variant="outlined"
                                name='state'
                                onChange={(e)=>handleInputChange(e)}
                                defaultValue= {data.recipient.state || ''}
                                sx={{ m: 1, width: '30ch'}}/>
                            <TextField
                                label="City"
                                variant="outlined"
                                name='city'
                                onChange={(e)=>handleInputChange(e)}
                                defaultValue= {data.recipient.city || ''}
                                sx={{ m: 1, width: '30ch'}}/>
                        </div>
                        <div className={classes.inputWrap}>
                            <TextField
                                label="Street 1"
                                variant="outlined"
                                name='street1'
                                onChange={(e)=>handleInputChange(e)}
                                defaultValue= {data.recipient.street1 || ''}
                                sx={{ m: 1, width: '30ch'}}/>
                            <TextField
                                label="Street 2"
                                variant="outlined"
                                name='street2'
                                onChange={(e)=>handleInputChange(e)}
                                defaultValue= {data.recipient.street2 || ''}
                                sx={{ m: 1, width: '30ch'}}/>
                        </div>
                    </div>
                    <div className={classes.buttonsWrap}>
                        <Button
                            variant="outlined"
                            onClick={()=>{
                                setFormData({});
                                setRecipientFormData({});
                                popUpHandler(true)
                            }
                            }
                        >
                           Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            disabled={Object.keys(formData).length === 0 && Object.keys(recipient).length === 0 ? true : false}
                            onClick={()=>updateOrder()}
                        >
                            Update Order
                        </Button>
                    </div>
                </div>
            </div>
        }
        </>
    );
}