import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    maxHeight: 75,
    boxShadow: ['0px', '2px', '13px', 'rgba(0, 0, 0, 0.065696)'],
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftPart: {
    display: 'flex',
  },
  rightPart: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 75,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  userName: {
    marginLeft: 13,
  },
  imgContainer: {
    marginLeft: 58,
    width: 108,
    paddingTop: 10,
  },
  adminPanel: {
    borderLeft: [6, 'solid', '#047d95'],
    height: 45,
    paddingLeft: 19,
    fontSize: '22px',
    color: '#047d95',
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 5,
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '42px',
  },
  popup: {
    width: 218,
    height: 55,
    borderRadius: 9,
    backgroundColor: '#EEEEEE',
    position: 'absolute',
    top: 86,
    right: 27,
    zIndex: 100,
  },
  popupItem: {
    lineHeight: '53px',
    height: 53,
    paddingLeft: 23,
    paddingRight: 23,
    display: 'flex',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  popupIcon: {
    marginRight: 13,
    position: 'relative',
    top: 7,
  },
  border: {
    width: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottom: [1, 'solid', 'rgba(19,19,19, 0.25)']
  },
});
