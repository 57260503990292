export default {
    setUsers: (state, param) => {
        const {payload} = param;
        return state.data = payload;
    },
    setUser: (state, param) => {
        const {payload} = param;
        state.data[payload.id] = payload
    },
    setAuthenticatedUser: (state, param) => {
        const {payload} = param;
        state.data = payload
    },
}
