import { getFullCustomerHistory} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../store";
import {setNotifications} from "../slices/notifications";
import {logOutUser} from "./auth";

export const getCustomerHistory = createAsyncThunk(
    'customerHistory/getCustomerFullHistory',
    async (customerId: any, {rejectWithValue}) => {
        try {
            const customerHistory= await getFullCustomerHistory(customerId);
            return customerHistory;
        } catch (error: any) {
            if(error.message === 'Unauthorized!'){
                store.dispatch(logOutUser())
            }
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
);


