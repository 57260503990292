import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Button, CircularProgress} from "@mui/material";
import * as tus from 'tus-js-client'
import {getVideoUrl} from "../../../api";
import Skeleton from "@mui/material/Skeleton";
import {Notifications} from "../../../store/slices";



export default function StoryVideo(props: any) {
    const {onChange} = props;
    const [isPopUp, setPopUp] = useState(false)
    const [video, setVideo] = useState<File | null>()
    const [videoUrl, setVideoUrl] = useState<string | null>(null)
    const [pending, setPending] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    const inputImageHandler = (event)=>{
        setVideo(event.target.files[0]);
    }

    const saveVideo = async ()=> {

        setPending(true);
        const data = getVideoUrl();
        data.then( (res) => {
            // @ts-ignore
            const upload = new tus.Upload(video, {
                endpoint: "https://video.bunnycdn.com/tusupload",
                retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
                headers: {
                    AuthorizationSignature: res.presigned_signature, // SHA256 signature (library_id api_key + ex
                    AuthorizationExpire: res.expire, // Expiration time as in the signature,
                    VideoId: res.guid, // The guid of a previously created video object through the Create Video AP
                    LibraryId: res.libId,
                },
                metadata: {
                    filetype: video?.type,
                },
                onError: function(error) {
                    console.log("Failed because: " + error)
                    dispatch(Notifications.setNotifications({text: 'Something went wrong, please try again later', type: 'error'}))
                    setPending(false)
                },
                onProgress: function(bytesUploaded, bytesTotal) {
                    const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                },
                onSuccess: function() {
                    setTimeout(() => {
                        onChange({target:{name:'videoLink', value: `${res.libId}/${res.guid}`}})
                        setVideoUrl(`${res.libId}/${res.guid}`);
                        setPending(false);
                        setVideo(null);
                        popUpHandler();
                        dispatch(Notifications.setNotifications({text: 'Video uploaded, don\'t forget save changes!', type: 'warning'}))
                    }, 5000);
                }
            })
            // Check if there are any previous uploads to continue.
            upload.findPreviousUploads().then(function (previousUploads) {
                // Found previous uploads so we select the first one.
                if (previousUploads.length) {
                    upload.resumeFromPreviousUpload(previousUploads[0])
                }

                // Start the upload
                upload.start()
            })
        })
    }

    const popUpHandler = (cancel?: boolean) =>{
        cancel && setVideo(null)
        setPopUp(prevState => !prevState)
    }
    return (
        <div className={classes.blockWrap}>
            <div className={classes.blockLabel}>Story Video</div>
            <div className={classes.imageWrap}>
                <Button
                    className={classes.editButton}
                    variant="contained"
                    onClick={()=>popUpHandler()}
                >
                    Edit
                </Button>
                {videoUrl ?<iframe
                    src={`https://iframe.mediadelivery.net/embed/${videoUrl}?autoplay=false`}
                    loading="lazy" style={{border: 'none', width:'100%', height: 'auto', maxWidth: '100%', maxHeight: '100%'}}
                    allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"
                    allowFullScreen>
                </iframe>
                    : <div className={classes.textWrapper} onClick={()=>popUpHandler()}>
                        Add video
                    </div>
                }
            </div>
            {isPopUp &&
                <div className={classes.popUpWrapper}>
                    <div className={classes.popUp}>
                        {pending && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}
                        <div className={classes.popUpImage}>
                            {video ?
                                <video
                                    className="VideoInput_video"
                                    width="100%"
                                    height={400}
                                    controls
                                    src={URL.createObjectURL(video)}
                                />
                            :
                                <div className={classes.textWrapper}>
                                    Please add video
                                </div>
                            }
                        </div>
                        <div className={classes.buttonsWrap}>
                            <Button
                                variant="outlined"
                                onClick={()=>popUpHandler(true)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                component="label"
                            >
                                Add Video
                                <input
                                    accept="video/*"
                                    name="video"
                                    type="file"
                                    onChange={inputImageHandler}
                                    hidden
                                />
                            </Button>
                            {video &&
                                <Button
                                variant="contained"
                                onClick={saveVideo}
                            >
                                Use this
                            </Button>
                            }

                        </div>
                    </div>
                </div>
            }
        </div>
    );
}