import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** Components */
import Skeleton from '@mui/material/Skeleton';
import PageContainer from '../../components/PageContainer';
// import AuthorsTable from '../../components/AthorsTable';
import OrdersTimeTable from '../../components/OrdersTimeTable';
/** Selectors */
import {OrdersTime} from '../../store/slices'
import OrdersProductionPopUp from "../../components/OrdersProductionTime";

import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import OrdersTimeGraph from "../../components/OrdersTimeGraph";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const DEFAULT_PAGING_STATE = {
  perPage: 25,
  page: 1,
  total: 0
};


const TrackPage = () => {
  const {
    ordersTime,
    status,
    queryParams
  } = useSelector<any, { ordersTime: any, status: string , queryParams: any}>(state => ({
    ordersTime: state.ordersTime.data,
    status: state.ordersTime.status,
    queryParams: state.ordersTime.queryParams
  }))
    const [isPopUp, setPopUp] = useState(false);

    const popUpHandler = (cancel?: boolean) =>{
        setPopUp(prevState => !prevState)
    }

  const dispatch = useDispatch();
  const loadOrdersTime = (params) => {
    // @ts-ignore
    dispatch<any>(OrdersTime.getOrdersTime(params));
  };

  // if(ordersTime?.result?.length > 0){
  //     const labels = ordersTime.result.map(item => new Date(item.timestamp).toLocaleTimeString());
  //     const standardTimes = ordersTime.result.map(item => parseInt(item.standardProductionTime));
  //     const expeditedTimes = ordersTime.result.map(item => parseInt(item.expeditedProductionTime));
  //
  //     const chartData = {
  //         labels: labels,
  //         datasets: [
  //             {
  //                 label: 'Standard Production Time',
  //                 data: standardTimes,
  //                 borderColor: 'blue',
  //                 fill: false
  //             },
  //             {
  //                 label: 'Expedited Production Time',
  //                 data: expeditedTimes,
  //                 borderColor: 'red',
  //                 fill: false
  //             }
  //         ]
  //     };
  //
  //     const options = {
  //         scales: {
  //             x: {
  //                 title: {
  //                     display: true,
  //                     text: 'Timestamp'
  //                 }
  //             },
  //             y: {
  //                 title: {
  //                     display: true,
  //                     text: 'Production Time'
  //                 }
  //             }
  //         }
  //     };
  //
  // }




  useEffect(() => {
    loadOrdersTime({
      page: queryParams?.page || DEFAULT_PAGING_STATE.page,
      perPage: queryParams?.perPage || DEFAULT_PAGING_STATE.perPage,
    });
  }, [])
  return (
    <PageContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
      <h2 style={{marginTop: 0, color:'#047d95', marginBottom: '40px', fontWeight: 600}}>Production Time</h2>
          <div
              onClick={()=> popUpHandler()}
              style={{
                  padding:'8px 30px',
                  width: 'fit-content',
                  backgroundColor: '#01ac9e',
                  color: 'white',
                  cursor:'pointer',
                  fontWeight: 600,
                  borderRadius: 5,
                  height: "40px",
                  margin: '10px 10px'
              }}
          >
              Update Production Time
          </div>
      </div>
        <OrdersProductionPopUp isPopUp={isPopUp} popUpHandler={popUpHandler} loadOrders={loadOrdersTime}/>
    <div style={{display: 'flex'}}>
        <div style={{marginTop: 0, fontSize: '18px', marginBottom: '20px', fontWeight: 600 , marginRight: '20px'}}>
            Expedited Production Time : <span style={{fontSize:'24px', padding: '0 5px', borderBottom:'3px solid red'}}>{ordersTime?.currentProductionTime?.expeditedProductionTime}</span>
        </div>
        <div style={{marginTop: 0, fontSize: '18px', marginBottom: '20px', fontWeight: 600 }}>
            Standard Production Time : <span style={{fontSize:'24px', padding: '0 5px', borderBottom:'3px solid blue'}}>{ordersTime?.currentProductionTime?.standardProductionTime}</span>
        </div>
    </div>
        {(ordersTime?.result?.length > 0 && status !== 'loading') &&
            <div>
                <OrdersTimeGraph data={ordersTime.result.slice(0, 12)}/>
            </div>
        }

      {(ordersTime?.result?.length > 0 && status !== 'loading') &&
          <OrdersTimeTable ordersTime={ordersTime.result} pagingUpdate={loadOrdersTime}/>
      }
      {ordersTime?.result?.length < 1 && status !== 'loading' && <div>Don't found result</div>}
      {status === 'loading' &&
        <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
    </PageContainer>

  );
};

export default TrackPage;
