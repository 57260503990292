import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  notifications: {
    position: 'fixed',
    backgroundColor: 'transparent',
    right: 0,
    top: 60,
    minWidth: 300,
    zIndex: 100,
    overflow: 'visible',
    listStyle: 'none',
    color: '#ffffff',
    '& .success': {
      backgroundColor: '#60a66b',
    },

    '& .error': {
      backgroundColor: '#ea3546',
    },

    '& .warning': {
      backgroundColor: '#ff8c00',
    },
  },
  '@keyframes show': {
    '0%': {
      opacity: 0,
    },
    '20%': {
      opacity: 1,
    },
    '60%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
  wpapper: {
    width: 'auto',
    height: 60,
    margin: 20,
    padding: '20px 40px',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: '1s',
    animation: '$show 10s 1',
    animationFillMode: 'forwards',
    animationDelay: '1s',
    position:'relative'
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    display: 'block',
    padding: '0 5px 1px',
    color: 'black',
    fontWeight: 600,
    fontSize: '14px',
    opacity: 0.7,
    '&:hover': {
      opacity: 1
    }
  }
});
