import {getStories, getStory, getStoryGiftCarts, updateStory} from "../../thunk";

const getID = (id) => {
    return `a${id}a`
}

export default {
    [getStories.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getStories.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload.result.reduce((acc, prev) => ({...acc, [getID(prev.storyId)]: prev}), []);
        state.queryParams = {...action.meta.arg ,page: +action.payload.page, perPage: +action.payload.perPage, total: +action.payload.totalCount}
    },
    [getStories.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [getStory.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getStory.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data[getID(action.payload[0].storyId)] = {...state.data[getID(action.payload[0].storyId)], ...action.payload[0]}
    },
    [getStory.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [getStoryGiftCarts.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getStoryGiftCarts.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data[getID(action.meta.arg.id)] = {...state.data[getID(action.meta.arg.id)], giftCards: [...action.payload.result], giftCardsTotal:action.payload.totalCount }
    },
    [getStoryGiftCarts.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [updateStory.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [updateStory.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data[getID(action.meta.arg.id)] = {...state.data[getID(action.meta.arg.id)], ...action.meta.arg.formData}
    },
    [updateStory.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    }
}
