import React from 'react';
import {useNavigate} from "react-router";
import useStyles from './styles';
import Header from "../../components/Header";



const WithoutAccess = () => {
  const classes = useStyles();
    const navigator = useNavigate();
  return (
<div className={classes.unknownScreen}>
           <Header />
    <div className={classes.wrapper}>
        <div className={classes.label}>Registration successful.</div>
        <div className={classes.label2}> Access rights pending approval by administrator.</div>
        <div className={classes.label2}> Thank you.</div>
    </div>

</div>
  );
};

export default WithoutAccess;
