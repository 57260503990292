import React from 'react';
import PageContainer from '../../../components/PageContainer';

import {Paper} from "@mui/material";
import CreateStoryForm from "../../../components/CreateStoryForm";



const CreateStory = () => {

  return (
      <PageContainer>
          <h2 style={{marginTop: 0, color:'#047d95', marginBottom: '40px', fontWeight: 600}}>Create Story</h2>
          <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px 10px', maxHeight: '80vh', overflowY: 'scroll' }}>
              <CreateStoryForm />
          </Paper>
      </PageContainer>
  );
};

export default CreateStory;
