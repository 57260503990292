import {API_URL, API_URL_STORY} from '../constants'
import store from '../store';
import {setNotifications} from "../store/slices/notifications";
import {logOut} from "./auth";


export const getAllAuthors = async (queryParams) => {
    const token = store.getState().auth.data.token;
    console.log('queryParams',queryParams);
    const res = await fetch(`${API_URL_STORY}/secure/authors?page=${queryParams.page}&limit=${queryParams.perPage}`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        if(res.status === 401){
            await logOut();
            throw new Error('Unauthorized!');
        }
        throw new Error('Server Error!');
    }
    return await res.json();
};

export const getAuthorById = async (id) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL_STORY}secure/stories/${id}`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        if(res.status === 401){
            await logOut();
            throw new Error('Unauthorized!');
        }
        throw new Error('Server Error!');
    }
    return await res.json();
};

