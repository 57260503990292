import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    wrapper: {
        paddingBottom: '20px',
        position: 'relative'
    },
    header: {
        width:'100%',
        padding: '0 20px 20px',
        fontSize: '20px',
        textAlign: 'center',
        fontWeight: 600,
        '& span': {
            display: "block",
            maxWidth: '250px',
            paddingBottom: '20px',
            color: '#047d95',
            borderBottom: '2px solid #047d95',
            margin: '0 auto'
        }
    },
    inputWrap:{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px',
        flexWrap: 'nowrap',
        '&>div': {
            flexShrink: 1,
        },

    },
    columnInputs: {
        display: "flex",
        justifyContent: 'center',
        // maxWidth: '100%',
        // '&>div': {
        //     flexShrink: 1,
        // },
    },
    rightColumn: {
        display: "flex",
        flexDirection: "column",
        maxWidth: 'calc(100% - 320px)'
    },
    buttonWrap: {
        display: "flex",
        justifyContent: 'end',
        maxWidth: '940px',
        margin: '0 auto',
        padding: "20px"
    },
    giftCardLoader: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
    },
})