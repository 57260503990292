import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Stories} from '../../store/slices'

import { useStyles } from './styles';
import {useDispatch} from "react-redux";



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));




export default function AuthorsTable(props: any) {
    const { authors, paging, pagingUpdate } = props;

    const classes = useStyles();
    const navigator = useNavigate();
    const dispatch = useDispatch();

    const handleChangePage = (event: unknown, newPage: number) => {
        pagingUpdate({page: newPage+1, perPage: paging.perPage})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        pagingUpdate({page: 1, perPage: +event.target.value})
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 70 }}
                            >
                              Author ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Email
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Author Name
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Stories Count
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Rewards
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Shopify Id
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {authors
                            .map((author) => {
                                return (
                                    <StyledTableRow tabIndex={-1} key={author.authorId} style={{}}>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 70 }}
                                        >
                                            {author.authorId}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            {author.email}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {author.firstName}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            <div style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={()=> {
                                                dispatch(Stories.clearFilters());
                                                navigator(`/stories/?authorId=${author.authorId}`);
                                            }}>
                                                {author.storyCount}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            {author.rewards}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {author.shopifyId}
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={paging.total}
                rowsPerPage={paging.perPage}
                page={paging.page-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}