/** Common */
import React from 'react';
import { useStyles } from './styles';
import classNames from 'classnames';
// import * as notificationsActions from '../../store/slices/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeNotification } from '../../store/slices/notifications'

const Notifications = () => {
  const classes = useStyles();
  // @ts-ignore
  const notificationsList = useSelector((state)=>state.notifications.data);
  const dispatch = useDispatch();
  const removeNotifications = (id) => {
    dispatch(removeNotification(id));
  };
  return (
    <div className={classes.notifications}>
      {notificationsList?.map((item) => (
        <div key={item.id} className={classNames(classes.wpapper, `${item.type}`)}>
          {item?.text}
          <span onClick={() => removeNotifications(item.id)} className={classes.close}>X</span>
        </div>
      ))}
    </div>
  );
};

export default Notifications;
