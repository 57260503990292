import {getAllOrdersTime} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../store";
import {setNotifications} from "../slices/notifications";

export const getOrdersTime = createAsyncThunk(
    'ordersTime/getOrdersTime',
    async (queryParams, {rejectWithValue}) => {
        try {
            const ordersTime = await getAllOrdersTime(queryParams);
            return ordersTime;
        } catch (error: any) {
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
)

