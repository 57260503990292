import React, {useEffect, useState} from 'react';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import PageContainer from '../../../components/PageContainer';
import {IStory, IStore, IOneStory} from "../../../store/types";
import {Orders} from '../../../store/slices'

import GeneralForm from "../../../components/GeneralForm";
import {Button, Paper} from "@mui/material";
import StoryForm from "../../../components/StoryForm";
import GiftCadsForm from "../../../components/GiftCardsForm";
import Skeleton from "@mui/material/Skeleton";
import OrderCancelForm from "../../../components/OrderCancelForm";
import OrderHoldState from "../../../components/OrderHoldState";
import OrderEditForm from "../../../components/OrderEditForm";
import {useNavigate} from "react-router";
import {orders} from "../../../store/middlewares";

/** Components */
export type TOrderProps = {
    id: number
}
const getID = (id) => {
    return `a${id}a`
}

const DEFAULT_PAGING_STATE = {
    perPage: 100,
    page: 1,
    total: 0,
    sort: 'createdAt',
    sortDirection:'DESC'
};



const OneOrder = () => {
  const { id, companyId } = useParams();
  const dispatch = useDispatch();
  const [isCancelPopUp, setCancelPopUp] = useState(false);
  const [isHoldPopUp, setHoldPopUp] = useState(false);
  const [isEditPopUp, setEditPopUp] = useState(false);
  const classes = useStyles();
  const navigator = useNavigate();
  const order = useSelector<IStore, any>((state) => {
      return {data: { ...state.orders.data[getID(id) || '']}, status: state.orders.status, queryParams: state.orders.queryParams }});
  const ordersList = useSelector<IStore, any>((state) => {
        return [ ...state.orders.ordersList || '']});

  const role = useSelector<IStore, any>((state) => {
        return state.auth.data.role});
    const popUpCancelHandler = (cancel?: boolean) =>{
        setCancelPopUp(prevState => !prevState)
    }

    const getOrder = (id, companyId) => {
        if(ordersList.length < 1){
            dispatch<any>(Orders.addOrders({...DEFAULT_PAGING_STATE, companyId: companyId || ''})).then(()=>{
                dispatch<any>(Orders.getOrder({id: id || '', companyId: companyId || ''}))
            });
        } else{
            dispatch<any>(Orders.getOrder({id: id || '', companyId: companyId || ''}));
        }

    }

    const currentIndex = ordersList.indexOf(Number(id));
    // console.log('CURNT INDX', currentIndex, id, ordersList)

    let previousOrder = null;
    let nextOrder = null;


    if (currentIndex > 0) {
        previousOrder = ordersList[currentIndex - 1];
    }


    if (currentIndex < ordersList.length - 1) {
        nextOrder = ordersList[currentIndex + 1];
    }

    // if(currentIndex === ordersList.length - 2){
    //     dispatch<any>(Orders.addOrders({...DEFAULT_PAGING_STATE, companyId: companyId || '', page: DEFAULT_PAGING_STATE.page+1})).then(()=>{
    //         console.log('orders new upload')
    //         // dispatch<any>(Orders.getOrder({id: id || '', companyId: companyId || ''}))
    //     });
    // }


    const checkNextOrders = ()=> {
        if(currentIndex === ordersList.length - 2){
            if(ordersList.length !== order.queryParams.total ){
                dispatch<any>(Orders.addOrders({...order.queryParams, companyId: companyId || '', page: order.queryParams.page+1}));
            }
        }
    }

    const holdHandler =()=>{
        setHoldPopUp(prevState => !prevState)
    }
    const editPopUpHandler =()=>{
        console.log('edit')
        setEditPopUp(prevState => !prevState)
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowRight') {
                if(nextOrder !== null){
                    nextOrder && navigator(`/${order.data.clientId}/orders/${nextOrder}/details`);
                    nextOrder && getOrder(nextOrder,order.data.clientId);
                    nextOrder && checkNextOrders()
                }

            }
            if (event.key === 'ArrowLeft') {
                previousOrder && navigator(`/${order.data.clientId}/orders/${previousOrder}/details`);
                previousOrder && getOrder(previousOrder,order.data.clientId );
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [previousOrder, nextOrder, order]);

  useEffect(() => {
          getOrder(id, companyId);

  }, [])
    useEffect(() => {

    }, [order])

  return (
      <PageContainer>
          {order.status === 'resolved' &&
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <h2 style={{marginTop: 0, color:'#047d95', marginBottom: '40px', fontWeight: 600}}>Order Id : {id}</h2>
              {(role === 'moderator' || role === 'admin') &&
              <div>
              {order.data.orderStatusId === 5
                  ?<div style={{color: 'red', fontSize: '24px', fontWeight: '600'}}>
                      Canceled { order.data.requireCancelPayment === 1 ? 'with fee' : 'without fee'}
                  </div>
                  : <div style={{display: 'flex'}}>
                      <Button
                          style={{

                          }}
                          onClick={()=>holdHandler()}
                          className={classes.holdButton}
                          disabled={order.data.orderStatusId === 1 || order.data.orderStatusId === 2 ? false : true}
                      >
                          {order.data.onHold ? 'UnHold Order': 'Hold Order'}
                      </Button>
                      <Button
                          className={classes.cancelButton}
                          onClick={()=>popUpCancelHandler()}
                          disabled={order.data.orderStatusId === 3 ? true : false}
                      >
                          Cancel Order
                      </Button>
                      <Button
                          style={{

                          }}
                          className={classes.editButton}
                          onClick={()=>editPopUpHandler()}
                          disabled={order.data.orderStatusId === 1 || order.data.orderStatusId === 2 ? false : true}
                      >
                          Edit Order
                      </Button>
                  </div>
              }
              </div>}
          </div>}
          <OrderCancelForm isPopUp={isCancelPopUp} popUpHandler={popUpCancelHandler} getOrder={getOrder}/>
          <OrderHoldState isPopUp={isHoldPopUp} popUpHandler={holdHandler} holdStatus={order.data.onHold}/>
          <OrderEditForm isPopUp={isEditPopUp} popUpHandler={editPopUpHandler} data={order.data}/>
          {order.status === 'resolved' &&
          <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px 10px', maxHeight: '80vh', overflowY: 'scroll', position:'relative' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', position:'sticky', top:0, left:0, width:'100%', zIndex:100}}>
                  <Button
                      style={{

                      }}
                      onClick={()=>{navigator(`/${order.data.clientId}/orders/${previousOrder}/details`); getOrder(previousOrder,order.data.clientId );}}
                      className={classes.controlButton}
                      disabled={previousOrder ? false : true}
                  >
                      PREV
                  </Button>
                  <Button
                      style={{

                      }}
                      onClick={()=> {navigator(`/${order.data.clientId}/orders/${nextOrder}/details`); getOrder(nextOrder,order.data.clientId); checkNextOrders()}}
                      className={classes.controlButton}
                      disabled={nextOrder ? false : true}
                  >
                      NEXT
                  </Button>
              </div>
              {Object.keys(order.data).length !== 0 && <>
                  <GeneralForm data={order} />
              </>}
          </Paper>}
          {order.status === 'loading' &&
              <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
      </PageContainer>
  );
};

export default OneOrder;
