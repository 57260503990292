import {getUserInfo} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";

export const getUsers = createAsyncThunk(
    '',
    async (_, {rejectWithValue}) => {
        try {
            const AuthenticatedUser = await getUserInfo();
            return AuthenticatedUser;
        } catch (error: any) {
            console.log('error', error);
            return rejectWithValue(error.message)
        }
    }
)

