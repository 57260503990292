import {createOrder, getAllOrders, getOrderById, updateOrderById, getAllOrdersAnalytics} from '../../api';
import {createAsyncThunk} from "@reduxjs/toolkit";
import store from "../../store";
import {setNotifications} from "../slices/notifications";
import {logOutUser} from "./auth";

export const getOrders = createAsyncThunk(
    'orders/getOrders',
    async (queryParams: any, {rejectWithValue}) => {
        try {
            const orders = await getAllOrders(queryParams);
            return orders;
        } catch (error: any) {
            if(error.message === 'Unauthorized!'){
                store.dispatch(logOutUser())
            }
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
);

export const addOrders = createAsyncThunk(
    'orders/addOrders',
    async (queryParams: any, {rejectWithValue}) => {
        try {
            const orders = await getAllOrders(queryParams);
            return orders;
        } catch (error: any) {
            if(error.message === 'Unauthorized!'){
                store.dispatch(logOutUser())
            }
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
);

export const getOrder = createAsyncThunk(
    'orders/getOrder',
    async ({id, companyId}:any, {rejectWithValue}) => {
        try {
            const order = await getOrderById({id, companyId});
            return order;
        } catch (error: any) {
            if(error.message === 'Unauthorized!'){
                store.dispatch(logOutUser())
            }
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
);

export const getOrdersAnalytics = createAsyncThunk(
    'orders/getOrdersAnalytics',
    async (queryParams: any, {rejectWithValue}) => {
        try {
            const orders = await getAllOrdersAnalytics(queryParams);
            return orders;
        } catch (error: any) {
            if(error.message === 'Unauthorized!'){
                store.dispatch(logOutUser())
            }
            store.dispatch(setNotifications({text: error.message, type: 'error'}))
            return rejectWithValue(error.message)
        }
    }
);

