import {API_URL, API_URL_NEW,ORDER_API_URL} from '../constants'
import store from '../store';
import {setNotifications} from "../store/slices/notifications";
import {logOut} from "./auth";
import {logOutUser} from "../store/thunk";


export const getAllOrdersTime = async (queryParams) => {
    const token = store.getState().auth.data.token;
    console.log('queryParams',queryParams);
    const res = await fetch(`${API_URL_NEW}/admin-api/shop/production/production-time`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
    });
    if (!res.ok) {
        if(res.status === 401){
            await logOut();
            throw new Error('Unauthorized!');
        }
        throw new Error('Server Error!');
    }
    return await res.json();
};

export const updateOrdersTime = async ({data,  onSuccess}) => {
    const token = store.getState().auth.data.token;
    const res = await fetch(`${API_URL_NEW}/admin-api/shop/production/production-time`,{
        method: "POST",
        // mode: "no-cors", // no-cors, *cors, same-origin
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
            // 'Access-Control-Allow-Origin': '*',
            "Authorization": `Bearer ${encodeURIComponent(token)}`
        },
        body:JSON.stringify({...data}),
    });
    if (!res.ok) {
        if(res.status === 401){
            store.dispatch(logOutUser())
            throw new Error('Unauthorized!');
        }
        throw new Error('Server Error!');
    }
    const rest = await res.json();
    if (onSuccess) {
        onSuccess(rest);
    }
    return rest;
};


