import {createSlice} from '@reduxjs/toolkit';
// import {IOneStory} from '../../types'
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/ordersTime';

const OrdersTimeSlice = createSlice({
    name: "ordersTime",
    initialState: {
        data: <any>{},
        status: null,
        error: null,
        queryParams: null,
    },
    reducers,
    extraReducers
});
const {actions, reducer} = OrdersTimeSlice
export const { setOrdersTime } = actions;
export {reducer};
