import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** Components */
import Skeleton from '@mui/material/Skeleton';
import PageContainer from '../../components/PageContainer';
import StoriesTable from '../../components/StoriesTable';
import StoriesFilters from '../../components/StoriesFilters';
/** Selectors */
import { Orders }  from '../../store/slices'
import {useParams, useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {IOneOrder, IOrder, IStore} from "../../store/types";
import OrdersTable from "../../components/OrdersTable";
import OrdersFilters from "../../components/OrdersFilters";
import {Button, CircularProgress} from "@mui/material";
import OrdersReport from "../../components/OrdersReport";
import OrdersProduction from "../../components/OrdersProduction";

const DEFAULT_PAGING_STATE = {
  perPage: 100,
  page: 1,
  total: 0,
  sort: 'createdAt',
  sortDirection:'DESC'
};


const OrdersPage = () => {
  const {
    orders,
    status,
    queryParams
  } = useSelector<IStore, { orders: Record<string, IOneOrder>[], status: string , queryParams: any}>(state => ({
    orders: Object.values(state.orders.data),
    status: state.orders.status,
    queryParams: state.orders.queryParams
  }))
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isReportPopUp, setReportPopUp] = useState(false);
  const [isProductionPopUp, setProductionPopUp] = useState(false);
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const loadOrders = (queryParams) => {
      console.log('params', queryParams)
    dispatch<any>(Orders.getOrders(queryParams));
  };

    const popUpHandler = (cancel?: boolean) =>{
        setReportPopUp(prevState => !prevState)
    }

    const productionHandler = (cancel?: boolean) =>{
        setProductionPopUp(prevState => !prevState)
    }

  useEffect(() => {
    loadOrders({
      ...queryParams,
      companyId: id,
      page: queryParams?.page || DEFAULT_PAGING_STATE.page,
      perPage: queryParams?.perPage || DEFAULT_PAGING_STATE.perPage,
      sortDirection: queryParams?.sortDirection || DEFAULT_PAGING_STATE.sortDirection,
      sort: queryParams?.sort || DEFAULT_PAGING_STATE.sort,
    });
  }, [id])
  return (
    <PageContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <h2 style={{marginTop: 0, color:'#047d95', fontWeight: 600}}>Orders
        </h2>
          <div style={{display: 'flex'}}>
                <div
                    onClick={()=> popUpHandler()}
                    style={{
                      padding:'8px 30px',
                      width: 'fit-content',
                      backgroundColor: '#01ac9e',
                      color: 'white',
                      cursor:'pointer',
                      fontWeight: 600,
                      borderRadius: 5,
                      height: "40px",
                      margin: '10px 10px'
                      }}
                >
                  Get Report CSV
                </div>
              <div
                  onClick={()=> productionHandler()}
                  style={{
                      padding:'8px 30px',
                      width: 'fit-content',
                      backgroundColor: 'rgba(255, 165, 0, 0.67)',
                      color: 'black',
                      cursor:'pointer',
                      fontWeight: 600,
                      borderRadius: 5,
                      height: "40px",
                      margin: '10px 10px'
                  }}
              >
                  Get Production
              </div>
        </div>
      </div>
        <OrdersReport isPopUp={isReportPopUp} popUpHandler={popUpHandler}/>
        <OrdersProduction queryParams={{...DEFAULT_PAGING_STATE, ...queryParams}} isPopUp={isProductionPopUp} popUpHandler={productionHandler} loadOrders={loadOrders}/>
      {/*{(status !== 'loading') &&*/}
          <OrdersFilters queryParams={{...DEFAULT_PAGING_STATE, ...queryParams}} loadOrders={loadOrders} loading={status === 'loading'}/>
      {/*}*/}
      {(orders?.length > 0 && status !== 'loading') &&
          <OrdersTable orders={orders} paging={{...DEFAULT_PAGING_STATE, ...queryParams}} pagingUpdate={loadOrders}/>
      }
      {orders?.length < 1 && status !== 'loading' && <div>Don't found result</div>}
      {status === 'loading' &&
        <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
    </PageContainer>

  );
};

export default OrdersPage;
