import {getAuthors, getAuthor} from "../../thunk";

const getID = (id) => {
    return `a${id}a`
}

export default {
    [getAuthors.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getAuthors.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload.result.reduce((acc, prev) => ({...acc, [getID(prev.authorId)]: prev}), []);
        state.queryParams = {page: +action.payload.page, perPage: +action.payload.perPage, total: +action.payload.totalCount}
    },
    [getAuthors.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [getAuthor.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getAuthor.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data[getID(action.payload[0].authorId)] = {...state.data[getID(action.payload[0].authorId)], ...action.payload[0]}
    },
    [getAuthor.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
}
