import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  unknownScreen: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display:'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    width: '100%',
    minHeight: '80vh',
    flexDirection: 'column',
    overflow: 'scroll',
    position: 'relative'
  },
  error: {
    fontSize: '50px',
    color: 'grey',
    paddingBottom: '50px'
  },
  label: {
    fontSize: '60px',
    color: '#047d95',
    paddingBottom: '60px',
    fontWeight: 600,
  },
  link: {
    fontSize: '20px',
    color: '#1da1f2',
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'underline'
  },
  imageWrapp: {
    width: 500,
    height: 500,
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
    }
  },
  imageInfo: {
    display: "block"
  },
  buttonWrapp: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: 20,
    width: '100%',
  },
  bodyWrap: {
    display: 'flex',
    maxWidth: '100%'
  },
  popUpLoader: {
    width: '100%',
    height: '100%',
    backgroundColor: '#80808080',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    borderRadius: '20px',
  },
  qulityWrapp: {
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  qualityRow: {
    width: '80%',
    display: "flex",
    position:"relative"
  },
  qualityRed: {
    backgroundColor: "red",
    height: '10px',
    width: '33.3%',
  },
  qualityYelow: {
    backgroundColor: "yellow",
    height: '10px',
    width: '33.3%',
  },
  qualityGreen: {
    backgroundColor: "green",
    height: '10px',
    width: '33.3%',
  },
  qualityIndefy: {
    position:"absolute",
    left: 0,
    width: '3px',
    height: '12px',
    top: '-1px',
    backgroundColor: 'black'
  }
});

export default useStyles;
