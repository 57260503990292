import {createSlice} from '@reduxjs/toolkit';
// import {IOneStory} from '../../types'
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/authors';

const AuthorsSlice = createSlice({
    name: "authors",
    initialState: {
        data: <any>{},
        status: null,
        error: null,
        queryParams: null,
    },
    reducers,
    extraReducers
});
const {actions, reducer} = AuthorsSlice
export const {setAuthors, setAuthor} = actions;
export {reducer};
