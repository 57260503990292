import {createSlice} from '@reduxjs/toolkit';
import {IUser} from '../../types'
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/users';

const usersSlice = createSlice({
    name: "users",
    initialState: {
        data: <Record<string, any>>{},
        status: null,
        error: null
    },
    reducers,
    extraReducers
});
const {actions, reducer} = usersSlice
export const {setUsers} = actions;
export {reducer};
