import {createSlice} from '@reduxjs/toolkit';
import {IOneStory} from '../../types'
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/stories';

const StoriesSlice = createSlice({
    name: "stories",
    initialState: {
        data: <Record<string,IOneStory>>{},
        status: null,
        error: null,
        queryParams: null,
    },
    reducers,
    extraReducers
});
const {actions, reducer} = StoriesSlice
export const {setStories, setStory, clearFilters} = actions;
export {reducer};
