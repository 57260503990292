import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    editButton: {
        padding:[7, 30],
        width: 'fit-content',
        margin: '0 auto',
        backgroundColor: '#047d95',
        color: 'white',
        cursor:'pointer',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#005566',
        }
    },
    filtersWrap: {
        marginBottom: '25px'
    }
})