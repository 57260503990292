import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bar, Pie, Doughnut } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';



/** Components */
import Skeleton from '@mui/material/Skeleton';
import PageContainer from '../../components/PageContainer';
import StoriesTable from '../../components/StoriesTable';
import StoriesFilters from '../../components/StoriesFilters';
/** Selectors */
import { Analytics3d }  from '../../store/slices'
import {useParams, useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {IOneOrder, IOrder, IStore} from "../../store/types";
import OrdersTable from "../../components/OrdersTable";
import OrdersFilters from "../../components/OrdersFilters";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OrdersReport from "../../components/OrdersReport";
import OrdersProduction from "../../components/OrdersProduction";
import Analutics3dTable from "../../components/Analytics3dTable";

const DEFAULT_PAGING_STATE = {
  perPage: 500,
  page: 1,
  total: 0,
  sort: 'createdAt',
  sortDirection:'DESC'
};

Chart.register(CategoryScale, LinearScale, BarElement, ArcElement, Tooltip, Legend,  Title, ChartDataLabels);
const AnalyticsPage = () => {
  const {
    analytics3d,
    status,
    queryParams
  } = useSelector<IStore, { analytics3d: any, status: string , queryParams: any}>(state => ({
    analytics3d: state.analytics3d.data,
    status: state.analytics3d.status,
    queryParams: state.analytics3d.queryParams
  }))
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isReportPopUp, setReportPopUp] = useState(false);
  const [isProductionPopUp, setProductionPopUp] = useState(false);
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const loadOrders = (queryParams) => {
      console.log('params', queryParams)
    dispatch<any>(Analytics3d.getAnalytics3d(queryParams));
  };
  const mockupData24ours = {
    actualMockupDurationAvg: analytics3d?.last24HoursStats?.actualMockupDurationAvg,
    mockupDurationAvg: analytics3d?.last24HoursStats?.mockupDurationAvg,
    validMockupCount: analytics3d?.last24HoursStats?.validMockupCount,
    validMockupCountForAvg: analytics3d?.last24HoursStats?.validMockupCountForAvg,
    validActualMockupCount: analytics3d?.last24HoursStats?.validActualMockupCount,
    validActualMockupCountForAvg: analytics3d?.last24HoursStats?.validActualMockupCountForAvg,
  };
  const processData24ours = {
    processDurationAvg: analytics3d?.last24HoursStats?.processDurationAvg,
    validProcessCount: analytics3d?.last24HoursStats?.validProcessCount,
    validProcessCountForAvg: analytics3d?.last24HoursStats?.validProcessCountForAvg,
  };
  const savingData24ours = {
    savingDurationAvg: analytics3d?.last24HoursStats?.savingDurationAvg,
    validSavingCount: analytics3d?.last24HoursStats?.validSavingCount,
    validSavingCountForAvg: analytics3d?.last24HoursStats?.validSavingCountForAvg,
  };

  const imageData24ours = {
    imgPerCustomerAvg: analytics3d?.last24HoursStats?.imgPerCustomerAvg,
    imgPerSessionToCartAvg: analytics3d?.last24HoursStats?.imgPerSessionToCartAvg,
    customerToCartPct: analytics3d?.last24HoursStats?.customerToCartPct,
    customerCount: analytics3d?.last24HoursStats?.customerCount
  };
  const actualMockupDurationPctCat24 = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Mockup Duration',
        data: [
          analytics3d?.last24HoursStats?.actualMockupDurationPctCat.fast,
          analytics3d?.last24HoursStats?.actualMockupDurationPctCat.medium,
          analytics3d?.last24HoursStats?.actualMockupDurationPctCat.slow,
          analytics3d?.last24HoursStats?.actualMockupDurationPctCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const imgPerCustomerPctCat24 = {
    labels: ['lessthan2', 'between2and3', 'between4and6', 'morethan6'],
    datasets: [
      {
        label: 'Image Per Customer',
        data: [
          analytics3d?.last24HoursStats?.imgPerCustomerPctCat.lessthan2,
          analytics3d?.last24HoursStats?.imgPerCustomerPctCat.between2and3,
          analytics3d?.last24HoursStats?.imgPerCustomerPctCat.between4and6,
          analytics3d?.last24HoursStats?.imgPerCustomerPctCat.morethan6
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const imgPerSessionToCartPctCat24 = {
    labels: ['lessthan2', 'between2and3', 'between4and6', 'morethan6'],
    datasets: [
      {
        label: 'Image Per Session',
        data: [
          analytics3d?.last24HoursStats?.imgPerSessionToCartPctCat.lessthan2,
          analytics3d?.last24HoursStats?.imgPerSessionToCartPctCat.between2and3,
          analytics3d?.last24HoursStats?.imgPerSessionToCartPctCat.between4and6,
          analytics3d?.last24HoursStats?.imgPerSessionToCartPctCat.morethan6
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };
  const toCartPerCustomerPctCat24 = {
    labels: ['lessthan2', 'between2and3', 'between4and6', 'morethan6', 'zero'],
    datasets: [
      {
        label: 'Image Per Session',
        data: [
          analytics3d?.last24HoursStats?.toCartPerCustomerPctCat.lessthan2,
          analytics3d?.last24HoursStats?.toCartPerCustomerPctCat.between2and3,
          analytics3d?.last24HoursStats?.toCartPerCustomerPctCat.between4and6,
          analytics3d?.last24HoursStats?.toCartPerCustomerPctCat.morethan6,
          analytics3d?.last24HoursStats?.toCartPerCustomerPctCat.zero
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgb(234,99,255)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const savingDurationPctCat24 = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Saving Duration',
        data: [
          analytics3d?.last24HoursStats?.savingDurationPctCat.fast,
          analytics3d?.last24HoursStats?.savingDurationPctCat.medium,
          analytics3d?.last24HoursStats?.savingDurationPctCat.slow,
          analytics3d?.last24HoursStats?.savingDurationPctCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const savingDurationAvgCat24 = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Saving Duration by Category',
        data: [
          analytics3d?.last24HoursStats?.savingDurationAvgCat.fast,
          analytics3d?.last24HoursStats?.savingDurationAvgCat.medium,
          analytics3d?.last24HoursStats?.savingDurationAvgCat.slow,
          analytics3d?.last24HoursStats?.savingDurationAvgCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const endStatusCountCat24 = {
    labels: ['Success', 'Error', 'NotEnded'],
    datasets: [
      {
        label: 'End Status Count',
        data: [
          analytics3d?.last24HoursStats?.endStatusCountCat.success,
          analytics3d?.last24HoursStats?.endStatusCountCat.error,
          analytics3d?.last24HoursStats?.endStatusCountCat.notEnded,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ]
      },
    ]
  };

  const endStatusNotEndedCat24 = {
    labels: ['LeftBefore10sPct', 'leftAfter10sPct', 'leftAfter20sPct'],
    datasets: [
      {
        label: 'Left Page',
        data: [
          analytics3d?.last24HoursStats?.endStatusNotEndedCat.leftBefore10sPct,
          analytics3d?.last24HoursStats?.endStatusNotEndedCat.leftAfter10sPct,
          analytics3d?.last24HoursStats?.endStatusNotEndedCat.leftAfter20sPct,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',

        ]
      },
    ]
  };

  const endStatusPctCat24 = {
    labels: ['Success', 'Error', 'NotEnded'],
    datasets: [
      {
        label: 'End Status Count',
        data: [
          analytics3d?.last24HoursStats?.endStatusPctCat.success,
          analytics3d?.last24HoursStats?.endStatusPctCat.error,
          analytics3d?.last24HoursStats?.endStatusPctCat.notEnded,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ]
      },
    ]
  };

  const endStatusErrorPct24 = {
    labels: ['Error_Mockup', 'Error_removeBG', 'Error_Network'],
    datasets: [
      {
        label: 'End Status Count',
        data: [
          analytics3d?.last24HoursStats?.endStatusErrorPct.err_mockup,
          analytics3d?.last24HoursStats?.endStatusErrorPct.err_network,
          analytics3d?.last24HoursStats?.endStatusErrorPct.err_removebg,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ]
      },
    ]
  };

  const actualProcessDurationPctCat24 = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Process Duration',
        data: [
          analytics3d?.last24HoursStats?.processDurationPctCat.fast,
          analytics3d?.last24HoursStats?.processDurationPctCat.medium,
          analytics3d?.last24HoursStats?.processDurationPctCat.slow,
          analytics3d?.last24HoursStats?.processDurationPctCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const actualMockupDurationAvgCat24 = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'], // Категорії на осі X
    datasets: [
      {
        label: 'Average Duration (in seconds)', // Легенда графіку
        data: [
          analytics3d?.last24HoursStats?.actualMockupDurationAvgCat.fast,
          analytics3d?.last24HoursStats?.actualMockupDurationAvgCat.medium,
          analytics3d?.last24HoursStats?.actualMockupDurationAvgCat.slow,
          analytics3d?.last24HoursStats?.actualMockupDurationAvgCat.extraSlow
        ], // Середні значення для кожної категорії
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(255, 99, 132, 0.6)',
        ], // Кольори для кожної категорії
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1, // Ширина контуру стовпців
      },
    ],
  };

  const actualProcessDurationAvgCat24 = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'], // Категорії на осі X
    datasets: [
      {
        label: 'Average Duration (in seconds)', // Легенда графіку
        data: [
          analytics3d?.last24HoursStats?.processDurationAvgCat.fast,
          analytics3d?.last24HoursStats?.processDurationAvgCat.medium,
          analytics3d?.last24HoursStats?.processDurationAvgCat.slow,
          analytics3d?.last24HoursStats?.processDurationAvgCat.extraSlow
        ], // Середні значення для кожної категорії
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(255, 99, 132, 0.6)',
        ], // Кольори для кожної категорії
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1, // Ширина контуру стовпців
      },
    ],
  };

  const optionsForBar: any = (titleText) => ({
    plugins: {
      legend: {
        display: true,
        position: 'top' as const, // Легенда розміщена зверху
      },
      title: {
        display: true,
        text: titleText,
        font: {
          size: 24, // Розмір заголовку
          weight: 'bold' as const, // Жирність заголовку
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: 'black',
        font: {
          weight: 'bold' as const,
          size: 14,
        },
         // Форматування значення (два знаки після коми)
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Початок шкали Y з нуля
        title: {
          display: true,
          text: 'Average Duration (seconds)', // Назва осі Y
        },
      },
    },
  });


  const optionsWithLegendOnRight:any = (titleText)=>({
    plugins: {
      legend: {
        position: 'left' as const, // Use 'as const' to enforce the exact string type
        align: 'center' as const,
      },
      title: {
        display: true,
        text: titleText,
        font: {
          size: 24, // Збільшуємо розмір шрифту заголовка до 24 пікселів
          weight: 'bold' as const, // Додаємо жирність шрифту для заголовка
        },
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        color: 'black',
        font: {
          weight: 'bold' as const,
          size: 14,
        },
        // Форматування значення (два знаки після коми)
      },
    },
  });

  const mockupDataLast = {
    actualMockupDurationAvg: analytics3d?.latestItemsStats?.actualMockupDurationAvg,
    mockupDurationAvg: analytics3d?.latestItemsStats?.mockupDurationAvg,
    validMockupCount: analytics3d?.latestItemsStats?.validMockupCount,
    validMockupCountForAvg: analytics3d?.latestItemsStats?.validMockupCountForAvg,
    validActualMockupCount: analytics3d?.latestItemsStats?.validActualMockupCount,
    validActualMockupCountForAvg: analytics3d?.latestItemsStats?.validActualMockupCountForAvg,
  };
  const processDataLast = {
    processDurationAvg: analytics3d?.latestItemsStats?.processDurationAvg,
    validProcessCount: analytics3d?.latestItemsStats?.validProcessCount,
    validProcessCountForAvg: analytics3d?.latestItemsStats?.validProcessCountForAvg,
  };
  const savingDataLast = {
    savingDurationAvg: analytics3d?.latestItemsStats?.savingDurationAvg,
    validSavingCount: analytics3d?.latestItemsStats?.validSavingCount,
    validSavingCountForAvg: analytics3d?.latestItemsStats?.validSavingCountForAvg,
  };

  const imageDataLast = {
    imgPerCustomerAvg: analytics3d?.latestItemsStats?.imgPerCustomerAvg,
    imgPerSessionToCartAvg: analytics3d?.latestItemsStats?.imgPerSessionToCartAvg,
    customerToCartPct: analytics3d?.latestItemsStats?.customerToCartPct,
    customerCount: analytics3d?.latestItemsStats?.customerCount
  };
  const actualMockupDurationPctCatLast = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Mockup Duration',
        data: [
          analytics3d?.latestItemsStats?.actualMockupDurationPctCat.fast,
          analytics3d?.latestItemsStats?.actualMockupDurationPctCat.medium,
          analytics3d?.latestItemsStats?.actualMockupDurationPctCat.slow,
          analytics3d?.latestItemsStats?.actualMockupDurationPctCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const imgPerCustomerPctCatLast = {
    labels: ['lessthan2', 'between2and3', 'between4and6', 'morethan6'],
    datasets: [
      {
        label: 'Image Per Customer',
        data: [
          analytics3d?.latestItemsStats?.imgPerCustomerPctCat.lessthan2,
          analytics3d?.latestItemsStats?.imgPerCustomerPctCat.between2and3,
          analytics3d?.latestItemsStats?.imgPerCustomerPctCat.between4and6,
          analytics3d?.latestItemsStats?.imgPerCustomerPctCat.morethan6
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const imgPerSessionToCartPctCatLast = {
    labels: ['lessthan2', 'between2and3', 'between4and6', 'morethan6'],
    datasets: [
      {
        label: 'Image Per Session',
        data: [
          analytics3d?.latestItemsStats?.imgPerSessionToCartPctCat.lessthan2,
          analytics3d?.latestItemsStats?.imgPerSessionToCartPctCat.between2and3,
          analytics3d?.latestItemsStats?.imgPerSessionToCartPctCat.between4and6,
          analytics3d?.latestItemsStats?.imgPerSessionToCartPctCat.morethan6
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };
  const toCartPerCustomerPctCatLast = {
    labels: ['lessthan2', 'between2and3', 'between4and6', 'morethan6', 'zero'],
    datasets: [
      {
        label: 'Image Per Customer',
        data: [
          analytics3d?.latestItemsStats?.toCartPerCustomerPctCat.lessthan2,
          analytics3d?.latestItemsStats?.toCartPerCustomerPctCat.between2and3,
          analytics3d?.latestItemsStats?.toCartPerCustomerPctCat.between4and6,
          analytics3d?.latestItemsStats?.toCartPerCustomerPctCat.morethan6,
          analytics3d?.latestItemsStats?.toCartPerCustomerPctCat.zero
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgb(234,99,255)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const savingDurationPctCatLast = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Saving Duration',
        data: [
          analytics3d?.latestItemsStats?.savingDurationPctCat.fast,
          analytics3d?.latestItemsStats?.savingDurationPctCat.medium,
          analytics3d?.latestItemsStats?.savingDurationPctCat.slow,
          analytics3d?.latestItemsStats?.savingDurationPctCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const savingDurationAvgCatLast = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Saving Duration by Category',
        data: [
          analytics3d?.latestItemsStats?.savingDurationAvgCat.fast,
          analytics3d?.latestItemsStats?.savingDurationAvgCat.medium,
          analytics3d?.latestItemsStats?.savingDurationAvgCat.slow,
          analytics3d?.latestItemsStats?.savingDurationAvgCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const endStatusCountCatLast = {
    labels: ['Success', 'Error', 'NotEnded'],
    datasets: [
      {
        label: 'End Status Count',
        data: [
          analytics3d?.latestItemsStats?.endStatusCountCat.success,
          analytics3d?.latestItemsStats?.endStatusCountCat.error,
          analytics3d?.latestItemsStats?.endStatusCountCat.notEnded,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ]
      },
    ]
  };

  const endStatusNotEndedCatLast = {
    labels: ['LeftBefore10sPct', 'leftAfter10sPct', 'leftAfter20sPct'],
    datasets: [
      {
        label: 'Left Page',
        data: [
          analytics3d?.latestItemsStats?.endStatusNotEndedCat.leftBefore10sPct,
          analytics3d?.latestItemsStats?.endStatusNotEndedCat.leftAfter10sPct,
          analytics3d?.latestItemsStats?.endStatusNotEndedCat.leftAfter20sPct,
        ],
        backgroundColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',

        ]
      },
    ]
  };

  const endStatusPctCatLast = {
    labels: ['Success', 'Error', 'NotEnded'],
    datasets: [
      {
        label: 'End Status Count',
        data: [
          analytics3d?.latestItemsStats?.endStatusPctCat.success,
          analytics3d?.latestItemsStats?.endStatusPctCat.error,
          analytics3d?.latestItemsStats?.endStatusPctCat.notEnded,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ]
      },
    ]
  };

  const endStatusErrorPctLast = {
    labels: ['Error_Mockup', 'Error_removeBG', 'Error_Network'],
    datasets: [
      {
        label: 'End Status Count',
        data: [
          analytics3d?.latestItemsStats?.endStatusErrorPct.err_mockup,
          analytics3d?.latestItemsStats?.endStatusErrorPct.err_network,
          analytics3d?.latestItemsStats?.endStatusErrorPct.err_removebg,
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ]
      },
    ]
  };

  const actualProcessDurationPctCatLast = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'],
    datasets: [
      {
        label: 'Average Mockup Duration',
        data: [
          analytics3d?.latestItemsStats?.processDurationPctCat.fast,
          analytics3d?.latestItemsStats?.processDurationPctCat.medium,
          analytics3d?.latestItemsStats?.processDurationPctCat.slow,
          analytics3d?.latestItemsStats?.processDurationPctCat.extraSlow
        ],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ]
      },
    ]
  };

  const actualMockupDurationAvgCatLast = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'], // Категорії на осі X
    datasets: [
      {
        label: 'Average Duration (in seconds)', // Легенда графіку
        data: [
          analytics3d?.latestItemsStats?.actualMockupDurationAvgCat.fast,
          analytics3d?.latestItemsStats?.actualMockupDurationAvgCat.medium,
          analytics3d?.latestItemsStats?.actualMockupDurationAvgCat.slow,
          analytics3d?.latestItemsStats?.actualMockupDurationAvgCat.extraSlow
        ], // Середні значення для кожної категорії
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(255, 99, 132, 0.6)',
        ], // Кольори для кожної категорії
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1, // Ширина контуру стовпців
      },
    ],
  };

  const actualProcessDurationAvgCatLast = {
    labels: ['Fast', 'Medium', 'Slow', 'ExtraSlow'], // Категорії на осі X
    datasets: [
      {
        label: 'Average Duration (in seconds)', // Легенда графіку
        data: [
          analytics3d?.latestItemsStats?.processDurationAvgCat.fast,
          analytics3d?.latestItemsStats?.processDurationAvgCat.medium,
          analytics3d?.latestItemsStats?.processDurationAvgCat.slow,
          analytics3d?.latestItemsStats?.processDurationAvgCat.extraSlow
        ], // Середні значення для кожної категорії
        backgroundColor: [
          'rgba(75, 192, 192, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(255, 99, 132, 0.6)',
        ], // Кольори для кожної категорії
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
        ],
        borderWidth: 1, // Ширина контуру стовпців
      },
    ],
  };

  useEffect(() => {
    loadOrders({
      ...queryParams,
      perPage: queryParams?.perPage || DEFAULT_PAGING_STATE.perPage,
    });
  }, [])
  return (
    <PageContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <h2 style={{marginTop: 0, color:'#047d95', fontWeight: 600}}>3D-Analytics
        </h2>

      </div>
      {(analytics3d && status !== 'loading') &&
      <Box sx={{ width: '100%', typography: 'body1', maxHeight: '80vh', overflow: 'scroll', border: '1px solid #dddddd', borderRadius: '10px' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="24hours" value="1" />
              <Tab label="last500" value="2" />
              <Tab label="table" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <>
              <h2>Status data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Doughnut data={endStatusPctCat24} options={optionsWithLegendOnRight('End Status in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={endStatusCountCat24} options={optionsForBar('End Status in Count')} />
                  </div>
                  <div style={{width: '400px'}}>
                    <Pie data={endStatusErrorPct24} options={optionsWithLegendOnRight('Error by Category in %')} />
                  </div>
                </div>
              </div>
            </>
            <>
              <h2>Process Duration data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={actualProcessDurationPctCat24} options={optionsWithLegendOnRight('Average Process Duration in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={actualProcessDurationAvgCat24} options={optionsForBar('Average Process Duration by Category')} />
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(processData24ours).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{processData24ours[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Mockup Duration data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={actualMockupDurationPctCat24} options={optionsWithLegendOnRight('Average Mockup Duration in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={actualMockupDurationAvgCat24} options={optionsForBar('Average Mockup Duration by Category')} />
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(mockupData24ours).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{mockupData24ours[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Saving Duration data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={savingDurationPctCat24} options={optionsWithLegendOnRight('Saving Duration in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={savingDurationAvgCat24} options={optionsForBar('Saving Duration by Category')} />
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(savingData24ours).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{savingData24ours[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Cart data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={toCartPerCustomerPctCat24} options={optionsWithLegendOnRight('Add to cart in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Doughnut data={imgPerSessionToCartPctCat24} options={optionsWithLegendOnRight('Add to cart per session in %')}/>
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(imageData24ours).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{imageData24ours[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Else</h2>
              <div style={{display: 'flex'}}>
                <div style={{width: '400px'}}>
                  <Pie data={endStatusNotEndedCat24} options={optionsWithLegendOnRight('Left by Category in %')} />
                </div>
                <div style={{width: '400px'}}>
                  <Pie data={imgPerCustomerPctCat24} options={optionsWithLegendOnRight('Image per Customer')} />
                </div>
              </div>
            </>
          </TabPanel>
          <TabPanel value="2">
            <>
              <h2>Status data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Doughnut data={endStatusPctCatLast} options={optionsWithLegendOnRight('End Status in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={endStatusCountCatLast} options={optionsForBar('End Status in Count')} />
                  </div>
                  <div style={{width: '400px'}}>
                    <Pie data={endStatusErrorPctLast} options={optionsWithLegendOnRight('Error by Category in %')} />
                  </div>
                </div>
              </div>
            </>
            <>
              <h2>Process Duration data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={actualProcessDurationPctCatLast} options={optionsWithLegendOnRight('Average Process Duration in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={actualProcessDurationAvgCatLast} options={optionsForBar('Average Process Duration by Category')} />
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(processDataLast).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{processDataLast[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Mockup Duration data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={actualMockupDurationPctCatLast} options={optionsWithLegendOnRight('Average Mockup Duration in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={actualMockupDurationAvgCatLast} options={optionsForBar('Average Mockup Duration by Category')} />
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(mockupDataLast).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{mockupDataLast[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Saving Duration data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={savingDurationPctCatLast} options={optionsWithLegendOnRight('Saving Duration in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Bar data={savingDurationAvgCatLast} options={optionsForBar('Saving Duration by Category')} />
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(savingDataLast).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{savingDataLast[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Cart data</h2>
              <div style={{display: 'flex'}}>
                <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                  <div style={{width: '400px'}}>
                    <Pie data={toCartPerCustomerPctCatLast} options={optionsWithLegendOnRight('Add to cart in %')}/>
                  </div>
                  <div style={{width: '400px'}}>
                    <Doughnut data={imgPerSessionToCartPctCatLast} options={optionsWithLegendOnRight('Add to cart per session in %')}/>
                  </div>
                </div>
                <div style={{ padding: '0 20px 20px'}}>
                  <table style={{ width: '100%', borderCollapse: 'collapse', margin: '0' }}>
                    <thead>
                    <tr>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Metric</th>
                      <th style={{ border: '1px solid #ddd', padding: '8px' }}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(imageDataLast).map((key) => (
                        <tr key={key}>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                          <td style={{ border: '1px solid #ddd', padding: '8px' }}>{imageDataLast[key]}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            <>
              <h2>Else</h2>
              <div style={{flexGrow: 1, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                <div style={{width: '400px'}}>
                  <Pie data={endStatusNotEndedCatLast} options={optionsWithLegendOnRight('Left by Category in %')} />
                </div>
                <div style={{width: '400px'}}>
                  <Pie data={imgPerCustomerPctCatLast} options={optionsWithLegendOnRight('Image per Customer')} />
                </div>
              </div>
            </>
          </TabPanel>
          <TabPanel value="3">
            <Analutics3dTable events={analytics3d?.result}/>
          </TabPanel>
        </TabContext>
      </Box>
      }
        {/*<OrdersReport isPopUp={isReportPopUp} popUpHandler={popUpHandler}/>*/}
        {/*<OrdersProduction queryParams={{...DEFAULT_PAGING_STATE, ...queryParams}} isPopUp={isProductionPopUp} popUpHandler={productionHandler} loadOrders={loadOrders}/>*/}
      {/*{(status !== 'loading') &&*/}
      {/*    <OrdersFilters queryParams={{...DEFAULT_PAGING_STATE, ...queryParams}} loadOrders={loadOrders} loading={status === 'loading'}/>*/}
      {/*}*/}
      {analytics3d?.length < 1 && status !== 'loading' && <div>Don't found result</div>}
      {status === 'loading' &&
        <Skeleton animation="wave" width={'100%'} height={600} variant="rounded" />}
    </PageContainer>

  );
};

export default AnalyticsPage;
