import {getOrdersTime} from "../../thunk";

const getID = (id) => {
    return `a${id}a`
}

export default {
    [getOrdersTime.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getOrdersTime.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload;
        state.queryParams = {}
    },
    [getOrdersTime.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
}
