import {createSlice} from '@reduxjs/toolkit';
import {IOneOrder} from '../../types'
import reducers from './reducers'
import extraReducers from './extraReducers'

export * from '../../thunk/orders';

const OrdersSlice = createSlice({
    name: "orders",
    initialState: {
        data: <Record<string, IOneOrder>>{},
        status: null,
        error: null,
        queryParams: null,
    },
    reducers,
    extraReducers
});
const {actions, reducer} = OrdersSlice
export const {setOrders, setOrder, clearFilters, addOrdersList} = actions;
export {reducer};
