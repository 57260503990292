/** Common */
import React, {useEffect} from 'react';
import { useStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {IAuth, IStore} from "../../store/types";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
/** Icons */
// import Logout from '@components/Icons/Logout';
// import UserCircle from '@components/Icons/UserCircle';
// import { getAuthState } from '@store/selectors';
import {Auth, Stories, Notifications} from '../../store/slices'
import {setNotifications} from '../../store/slices/notifications'
import {useNavigate} from "react-router";

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const logOut = () => {
        dispatch<any>(Auth.logOutUser())
  };
  const auth = useSelector<IStore, IAuth>((state) => state.auth.data);
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const test = () => {
      dispatch(Notifications.setNotifications({text: 'error', type: 'error'}))
      setTimeout(()=>{
          dispatch(setNotifications({text: 'success', type: 'success'}))
      }, 5000)
  }
  return (
    <div className={classes.root}>
      <div className={classes.leftPart}>
        <div className={classes.imgContainer} onClick={()=>navigator('/')}>
          <img alt="Logo" src="/images/newLogo.jpg" height={50} width={50} />
        </div>
        <div onClick={test} className={classes.adminPanel}>Admin Panel</div>
      </div>
      <div className={classes.rightPart} onClick={() => { setOpenPopup(!openPopup); }}>
          <AccountCircleIcon sx={{ color: '#047d95' }} fontSize={'medium'} />
        <div className={classes.userName}>
            {auth.name}
        </div>
      </div>
      <div className={classes.popup} style={{ display: openPopup ? 'block' : 'none' }}>
        <div onClick={() => { logOut(); }} className={classes.popupItem}>
          <div className={classes.popupIcon}><LogoutIcon /></div>
          <div>Logout</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
