import React from 'react';
import Header from '../Header';
import LeftNavigationBar from '../LeftNavigationBar';
import { useStyles } from './styles';
import Notifications from "../Notifications";

const PageContainer = (props: any) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.root}>
       <Notifications />
      <Header />
      <div className={classes.contentContainer}>
        <LeftNavigationBar />
      <div className={classes.pageContent}>
          {children}
      </div>
      </div>
    </div>
  );
};

export default PageContainer;
