import {getOrders, getOrder, addOrders, getOrdersAnalytics} from "../../thunk";

const getID = (id) => {
    return `a${id}a`
}

export default {
    [getOrders.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getOrders.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload.result.reduce((acc, prev) => ({...acc, [getID(prev.orderId)]: prev}), []);
        state.ordersList = action.payload.result.reduce((acc, prev) => ([...acc, prev.orderId]), []);
        state.queryParams = {...action.meta.arg ,page: +action.payload.page, perPage: +action.payload.perPage, total: +action.payload.totalCount}
    },
    [getOrders.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [getOrder.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getOrder.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data[getID(action.payload.orderId)] = {...state.data[getID(action.payload.orderId)], ...action.payload}
    },
    [getOrder.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [addOrders.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [addOrders.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = {...state.data,...action.payload.result.reduce((acc, prev) => ({...acc, [getID(prev.orderId)]: prev}), [])};
        state.ordersList = [...(Array.isArray(state.ordersList) ? state.ordersList : []),...action.payload.result.reduce((acc, prev) => ([...acc, prev.orderId]), [])];
        state.queryParams = {...action.meta.arg ,page: +action.payload.page, perPage: +action.payload.perPage, total: +action.payload.totalCount}
    },
    [addOrders.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [getOrdersAnalytics.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getOrdersAnalytics.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.ordersAnalytics = action.payload.result;
        state.ordersAnalyticsParams = {...action.meta.arg};
    },
    [getOrdersAnalytics.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    }
}
