import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Stories} from '../../store/slices'

import { useStyles } from './styles';
import {useDispatch} from "react-redux";



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));




export default function OrdersTimeTable(props: any) {
    const { ordersTime, pagingUpdate } = props;

    const classes = useStyles();
    const navigator = useNavigate();
    const dispatch = useDispatch();

    // const handleChangePage = (event: unknown, newPage: number) => {
    //     pagingUpdate({page: newPage+1, perPage: paging.perPage})
    // };
    //
    // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     pagingUpdate({page: 1, perPage: +event.target.value})
    // };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', height: '50vh' }}>
            <TableContainer sx={{ maxHeight: '100%' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Expedited Production Time
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Standard Production Time
                            </TableCell>

                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170 }}
                            >
                                Update date
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ordersTime
                            .map((timeLine) => {
                                return (
                                    <StyledTableRow tabIndex={-1} key={timeLine.timestamp} style={{}}>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            {timeLine.expeditedProductionTime}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            {timeLine.standardProductionTime}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            {new Date(timeLine.timestamp).toLocaleString("en-US")}
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}