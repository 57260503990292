export default {
    setStories: (state, param) => {
        const {payload} = param;
        return state.data = payload;
    },
    setStory: (state, param) => {
        const {payload} = param;
        state.data[payload.id] = payload
    },
    clearFilters: (state) => {
        state.queryParams = {perPage: 25, page: 1}
    }
}
