import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Button, Checkbox, CircularProgress, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import  Dayjs  from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Notifications, Orders} from "../../store/slices";
import debounce from 'lodash/debounce';
import * as yup from "yup";
import {useParams} from "react-router-dom";
import {disableGiftCard, getReportModerator, setCancelOrder} from "../../api";

const OrderStatus = [
    {status: 'Created', color:'#00ccff54', value: 1},
    {status: 'Production', color:'#ffa500ab', value: 2},
    {status: 'Shipped', color:'#4bd30899', value: 3},
    {status: 'Partial Shipped', color:'#8ffa98', value: 7},
    {status: 'Canceled', color:'#80808073', value: 5},
    {status: 'Error', color:'#ff000073', value: 6},
    {status: 'Hold', color:'rgba(224,0,255,0.45)', value: 8},
]

const DEFAULT_FILTERS = {
    sort: 'createdAt',
    sortDirection:'DESC',
    // createdAt_max: Dayjs(new Date()).format("YYYY-MM-DD")
};

const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]

export default function OrderCancelForm(props: any) {
    const { getOrder, isPopUp, popUpHandler } = props;
    const [reportFilters, setReportFilters] = useState<any>({...DEFAULT_FILTERS});
    const dataLink = useRef(null);
    const [data, setData] = useState();
    const [cancelMessage, setCancelMessage] = useState('');

    const dispatch = useDispatch();
    const { id, companyId } = useParams();
    console.log(id, companyId, 'companyID');
    const classes = useStyles();
    const [pending, setPending] = useState(false);
    const [searchError, setSearchError] = useState<any>({});

    const cancelHandle = () => {
        if(cancelMessage.length < 1){
            setSearchError({message: 'Field is required'})
            // handleFilterChange(e);
        } else {
            setSearchError({});
            return cancelOrder();
        }
    }

    const handleInputChange = (e)=>{
        setCancelMessage(e.target.value);
        if(e.target.value.length < 1){
            setSearchError({message: 'Field is required'})
            // handleFilterChange(e);
        } else {
            setSearchError({});
        }
    }

    const cancelOrder = async () => {
        setPending(true);
        try {
            await setCancelOrder({
                companyId: companyId,
                orderId: id,
                cancelMessage,
                onSuccess: async (data) => {
                    setData(data.downloadUrl);
                    dispatch(Notifications.setNotifications({text: "Order Canceled", type: 'success'}))
                    setReportFilters({...DEFAULT_FILTERS});
                    popUpHandler(true);
                    getOrder();
                }});
        } catch (error) {
            dispatch(Notifications.setNotifications({text: 'Something went wrong, please try again later', type: 'error'}))
        } finally {
            setPending(false);
        }
    }
    // @ts-ignore
    return (
        <>
            {isPopUp &&
            <div className={classes.popUpWrapper}>
                <div className={classes.popUp}>
                    {pending && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}
                    <div className={classes.popUpImage}>
                        <h3 style={{width:'100%', margin: '0', textAlign: 'center'}}>Cancel Order</h3>
                        <FormControl sx={{ m: 1,  width: 'calc(100% - 16px)' , marginTop: '24px'}}>
                            <TextField
                                id="outlined-multiline-static"
                                label="The reason for canceling the order"
                                name="reason"
                                multiline
                                onChange={(event) =>handleInputChange(event)}
                                rows={4}
                                error={Boolean(searchError?.message)}
                                helperText={searchError?.message}
                                required
                            />
                        </FormControl>
                    </div>
                    <div className={classes.buttonsWrap}>
                        <Button
                            variant="outlined"
                            onClick={()=>popUpHandler(true)}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            onClick={()=>cancelHandle()}
                        >
                            Cancel Order
                        </Button>
                    </div>
                </div>
            </div>
        }
        </>
    );
}