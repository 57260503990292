import React, { useState } from 'react';
import {PRINTFULL_ID, FREEPRINTS_ID, INK_ID} from '../../constants'
/** Icons */
import WebStoriesIcon from '@mui/icons-material/WebStories';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PhotoIcon from '@mui/icons-material/Photo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListAltIcon from '@mui/icons-material/ListAlt';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import HistoryIcon from '@mui/icons-material/History';
import { useStyles } from './styles';
/** Constants */
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {useSelector} from "react-redux";
import {IStore} from "../../store/types";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

/** Interfaces */
interface INavItem {
  title: string;
  icon: (size?: boolean) => JSX.Element;
  link: string;
  subNavigation?: { titleName: string; link: string }[];
}
const LeftNavigationBar = () => {

  const navigationItemsStories: INavItem[] = [
    { title: 'Authors', link: 'authors', icon: (size?: boolean) => <PeopleAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Stories', link: 'stories', icon: (size?: boolean) => <WebStoriesIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'} /> },
  ]

  const navigationItemsAPI: INavItem[] = [
    { title: 'Printful Orders', link: `${PRINTFULL_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Freeprints Orders', link: `${FREEPRINTS_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'INK Orders', link: `${INK_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    // { title: 'Test Orders', link: `19645/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Products', link: 'products', icon: (size?: boolean) => <InventoryIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Companies', link: 'companies', icon: (size?: boolean) => <StoreIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Analytics', link: 'orders-analytics', icon: (size?: boolean) => <ShowChartIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>}
  ]
  const navigationItemsAPIDesigner: INavItem[] = [
    { title: 'Printful Orders', link: `${PRINTFULL_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'Freeprints Orders', link: `${FREEPRINTS_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    { title: 'INK Orders', link: `${INK_ID}/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
    // { title: 'Test Orders', link: `19648/orders`, icon: (size?: boolean) => <ListAltIcon sx={{ color: '#047d95' }} fontSize={size ? 'large': 'medium'}/>},
  ]

  const role = useSelector<IStore, any>((state) => {
    return state.auth.data.role});
  const allRoles = useSelector<IStore, any>((state) => {
    return state.auth.data.allRoles});
  const classes = useStyles();
  const locationLink = useLocation().pathname.substring(1).toLowerCase() + useLocation().search;
  console.log('locationLink', locationLink);
  return (
      <div className={classes.root}>
        {role === 'admin' &&
            <>
              <Accordion className={classes.customAcordion} defaultExpanded={ locationLink.includes('orders-analytics') || locationLink.includes(FREEPRINTS_ID || '') || locationLink.includes(PRINTFULL_ID || '') || locationLink.includes(INK_ID || '') || locationLink.includes('19648')? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content"  expandIcon={<ExpandMoreIcon />}>
                  <b>API Integration</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsAPI.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  locationLink.includes(item.link),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes('stor') || locationLink.includes('author') ? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}>
                  <b>Customer Stories</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsStories.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  item.link.includes(locationLink),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className={classes.itemsWrapper}>
                <Link
                    to={`/3d-analytics`}
                    key={11}
                    className={classNames(classes.itemContainer, {
                      [classes.activeRoute]:
                          locationLink.includes('3d-analytics'),
                    })}
                >
                  <div className={classes.linkConetnt}>
                    <div className={classes.itemIcon}><ShowChartIcon sx={{ color: '#047d95' }}/></div>
                    <div className={classes.itemTitle}>3D-Analytics</div>
                  </div>
                </Link>
              </div>
              <div className={classes.itemsWrapper}>
                <Link
                    to={`/track-order`}
                    key={22}
                    className={classNames(classes.itemContainer, {
                      [classes.activeRoute]:
                          locationLink.includes('track-order'),
                    })}
                >
                  <div className={classes.linkConetnt}>
                    <div className={classes.itemIcon}><HourglassTopIcon sx={{ color: '#047d95' }}/></div>
                    <div className={classes.itemTitle}>Production Time</div>
                  </div>
                </Link>
              </div>
            </>
        }
        {role === 'moderator' &&
            <>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes('orders-analytics') || locationLink.includes(FREEPRINTS_ID || '') || locationLink.includes(PRINTFULL_ID || '') || locationLink.includes(INK_ID || '') || locationLink.includes('19648')? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content"  expandIcon={<ExpandMoreIcon />}>
                  <b>API Integration</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsAPI.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  locationLink.includes(item.link),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes('stor') || locationLink.includes('author') ? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}>
                  <b>Customer Stories</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsStories.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  locationLink.includes(item.link),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
              <div className={classes.itemsWrapper}>
                <Link
                    to={`/3d-analytics`}
                    key={1}
                    className={classNames(classes.itemContainer, {
                      [classes.activeRoute]:
                          locationLink.includes('3d-analytics'),
                    })}
                >
                  <div className={classes.linkConetnt}>
                    <div className={classes.itemIcon}><ShowChartIcon sx={{ color: '#047d95' }}/></div>
                    <div className={classes.itemTitle}>3D-Analytics</div>
                  </div>
                </Link>
              </div>
              <div className={classes.itemsWrapper}>
                <Link
                    to={`/3d-mockups`}
                    key={33}
                    className={classNames(classes.itemContainer, {
                      [classes.activeRoute]:
                          locationLink.includes('mockups'),
                    })}
                >
                  <div className={classes.linkConetnt}>
                    <div className={classes.itemIcon}><HistoryIcon sx={{ color: '#047d95' }}/></div>
                    <div className={classes.itemTitle}>Mockups History</div>
                  </div>
                </Link>
              </div>
              <div className={classes.itemsWrapper}>
                <Link
                    to={`/track-order`}
                    key={22}
                    className={classNames(classes.itemContainer, {
                      [classes.activeRoute]:
                          locationLink.includes('track-order'),
                    })}
                >
                  <div className={classes.linkConetnt}>
                    <div className={classes.itemIcon}><HourglassTopIcon sx={{ color: '#047d95' }}/></div>
                    <div className={classes.itemTitle}>Production Time</div>
                  </div>
                </Link>
              </div>
            </>
        }
        {role === 'designer' &&
            <>
              <Accordion className={classes.customAcordion} defaultExpanded={locationLink.includes(FREEPRINTS_ID || '') || locationLink.includes(PRINTFULL_ID || '') || locationLink.includes('19648')? true : false}>
                <AccordionSummary id="panel-header" aria-controls="panel-content"  expandIcon={<ExpandMoreIcon />}>
                  <b>API Integration</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsAPIDesigner.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  locationLink.includes(item.link),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
        }
        {role === 'stories' &&
            <>
              <Accordion className={classes.customAcordion} defaultExpanded={true}>
                <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}>
                  <b>Customer Stories</b>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.itemsWrapper}>
                    {navigationItemsStories.map((item: INavItem, index: number) => (

                        <Link
                            to={`/${item.link}`}
                            key={index}
                            className={classNames(classes.itemContainer, {
                              [classes.activeRoute]:
                                  locationLink.includes(item.link),
                            })}
                        >
                          <div className={classes.linkConetnt}>
                            <div className={classes.itemIcon}>{item.icon((locationLink.includes(item.link)) && true)}</div>
                            <div className={classes.itemTitle}>{item.title}</div>
                          </div>
                        </Link>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
        }
        {allRoles.includes("mockupHistory") &&
            <div className={classes.itemsWrapper}>
              <Link
                  to={`/3d-mockups`}
                  key={33}
                  className={classNames(classes.itemContainer, {
                    [classes.activeRoute]:
                        locationLink.includes('mockups'),
                  })}
              >
                <div className={classes.linkConetnt}>
                  <div className={classes.itemIcon}><HistoryIcon sx={{ color: '#047d95' }}/></div>
                  <div className={classes.itemTitle}>Mockups History</div>
                </div>
              </Link>
            </div>
        }
      </div>
  );
};

export default LeftNavigationBar;
