import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Button, Checkbox, CircularProgress, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import  Dayjs  from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Notifications, Orders} from "../../store/slices";
import debounce from 'lodash/debounce';
import * as yup from "yup";
import {useParams} from "react-router-dom";
import {disableGiftCard, getReportModerator, setCancelOrder, setHoldStatus, setVoidLabel} from "../../api";

const OrderStatus = [
    {status: 'Created', color:'#00ccff54', value: 1},
    {status: 'Production', color:'#ffa500ab', value: 2},
    {status: 'Shipped', color:'#4bd30899', value: 3},
    {status: 'Partial Shipped', color:'#8ffa98', value: 7},
    {status: 'Canceled', color:'#80808073', value: 5},
    {status: 'Error', color:'#ff000073', value: 6},
    {status: 'Hold', color:'rgba(224,0,255,0.45)', value: 8},
]

const DEFAULT_FILTERS = {
    sort: 'createdAt',
    sortDirection:'DESC',
    // createdAt_max: Dayjs(new Date()).format("YYYY-MM-DD")
};

const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]

export default function OrderHoldState(props: any) {
    const { holdStatus, isPopUp, popUpHandler } = props;

    const { id, companyId } = useParams();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [pending, setPending] = useState(false);

    const getOrder = () => {
        dispatch<any>(Orders.getOrder({id: id || '', companyId: companyId || ''}));
    }

    const voidLabel = async () => {
        setPending(true);
        try {
            await setHoldStatus({
                id,
                companyId,
                holdStatus,
                onSuccess: async (data) => {
                    dispatch(Notifications.setNotifications({text: "Hold Status Updated", type: 'success'}))
                    popUpHandler(true);
                    getOrder();
                }});
        } catch (error) {
            // @ts-ignore
            dispatch(Notifications.setNotifications({text: error.message, type: 'error'}))
        } finally {
            setPending(false);
        }
    }
    // @ts-ignore
    return (
        <>
            {isPopUp &&
            <div className={classes.popUpWrapper}>
                <div className={classes.popUp}>
                    {pending && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}
                    <div className={classes.popUpImage}>
                        <h3 style={{width:'100%', margin: '0', textAlign: 'center'}}>Are you sure you want to {holdStatus ? 'unHold': 'hold' } order?</h3>
                        <p style={{width:'100%', marginBottom: '0',textAlign: 'left'}}><b>Order ID: {id};</b></p>
                    </div>
                    <div className={classes.buttonsWrap}>
                        <Button
                            variant="outlined"
                            onClick={()=>popUpHandler(true)}
                        >
                           Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            onClick={()=>voidLabel()}
                        >
                            {holdStatus ? 'UnHold': 'Hold' } Order
                        </Button>
                    </div>
                </div>
            </div>
        }
        </>
    );
}