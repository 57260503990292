export default {
    setAuthors: (state, param) => {
        const {payload} = param;
        return state.data = payload;
    },
    setAuthor: (state, param) => {
        const {payload} = param;
        state.data[payload.id] = payload
    },
}
