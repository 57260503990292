import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {Button, Checkbox, CircularProgress, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import  Dayjs  from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Notifications, Orders} from "../../store/slices";
import debounce from 'lodash/debounce';
import * as yup from "yup";
import {useParams} from "react-router-dom";
import {updateOrdersTime} from "../../api";
import {IOneOrder, IStore} from "../../store/types";

const OrderStatus = [
   {status: '50 orders', color:'#00ccff54', value: 50},
   {status: '100 orders', color:'#00ccff54', value: 100},
   {status: '150 orders', color:'#ffa500ab', value: 150},
   {status: '200 orders', color:'#ffa500ab', value: 200},
   {status: '300 orders', color:'#ffa500ab', value: 200},
]

const DEFAULT_FILTERS = {
    standardProductionTime: 3,
    expeditedProductionTime: 2,
};

const StoryPublished = [
    // {status: 'All', value: null },
    {label: 'Yes', value: '1'},
    {label: 'No', value: '0'},
]

export default function OrdersProduction(props: any) {
    const { queryParams, loadOrders, loading, isPopUp, popUpHandler } = props;
    const [reportFilters, setReportFilters] = useState<any>({...DEFAULT_FILTERS});
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const dataLink = useRef(null);
    const [data, setData] = useState();

    const dispatch = useDispatch();
    const { id } = useParams();
    const classes = useStyles();
    const [pending, setPending] = useState(false);


    const handleFilterChange = (e) => {
        setError1(false);
        setError2(false);
        const { name, value } = e.target;
        const newQueryParams = {
            ...reportFilters,
            [name]: parseInt(value),
        }
        // if(name === 'maxDate' && !newQueryParams.minDate){
        //     newQueryParams.minDate = '2024-01-01'
        // }
        // if(name === 'minDate' && !newQueryParams.maxDate){
        //     newQueryParams.maxDate = Dayjs(new Date()).format("YYYY-MM-DD")
        // }

        console.log('PARAMS', newQueryParams)
        setReportFilters(newQueryParams);
    }


    const getReport = async () => {
        // setPending(true);
        if(isNaN(reportFilters.standardProductionTime)){
            setError1(true)
        } else if(isNaN(reportFilters.expeditedProductionTime)) {
            setError2(true)
        } else {
            try {
                await updateOrdersTime({
                    data: {...reportFilters},
                    onSuccess: async (data) => {
                        dispatch(Notifications.setNotifications({text: "Update", type: 'success'}))
                        loadOrders(queryParams);
                        popUpHandler(true);
                    }});
            } catch (error) {
                dispatch(Notifications.setNotifications({text: 'Something went wrong, please try again later', type: 'error'}))
            } finally {
                setPending(false);
            }
        }
    }
    useEffect(() => {
        if (data) {
            // @ts-ignore
            dataLink.current.click();
        }
    }, [data]);
    // @ts-ignore
    return (
        <>
            {isPopUp &&
            <div className={classes.popUpWrapper}>
                <a className="hidden-element" download href={data} ref={dataLink} rel={'nofollow'} target={'_blank'}></a>
                <div className={classes.popUp}>
                    {pending && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}
                    <p style={{marginBottom: '40px', fontSize: '22px', fontWeight:600, textAlign: 'center'}}>Update Production Time</p>
                    <div className={classes.popUpImage}>
                        <div className={classes.filtersWrap}>
                            <FormControl sx={{ m: 1,  width: '22ch' }} >
                                {/*<InputLabel id="demo-simple-select-helper-label">Standard Production Time</InputLabel>*/}

                                <TextField
                                    label="Standard Production Time"
                                    type='number'
                                    variant="outlined"
                                    name="standardProductionTime"
                                    defaultValue={reportFilters.standardProductionTime}
                                    required={true}
                                    error={error1}
                                    helperText={error1 ? 'This field is required' : ''}
                                    onChange={(e) => handleFilterChange(e)}
                                />
                            </FormControl>
                            <FormControl sx={{ m: 1,  width: '22ch', }} >
                                {/*<InputLabel id="demo-simple-select-helper-label">Expedited Production Time</InputLabel>*/}
                                <TextField
                                    label="Expedited Production Time"
                                    type='number'
                                    variant="outlined"
                                    name="expeditedProductionTime"
                                    defaultValue={reportFilters.expeditedProductionTime}
                                    required={true}
                                    error={error2}
                                    helperText={error2 ? 'This field is required' : ''}
                                    onChange={(e) => handleFilterChange(e)}
                                />
                            </FormControl>
                        </div>
                    </div>
                    <div className={classes.buttonsWrap}>
                        <Button
                            variant="outlined"
                            onClick={()=>popUpHandler(true)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            component="label"
                            onClick={()=>getReport()}
                        >
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        }
        </>
    );
}