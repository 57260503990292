import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    authorWrap: {
        paddingBottom: '20px',
        position: "relative"
    },
    giftCardLoader: {
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
    },
    authorHeader: {
        width:'100%',
        padding: '0 20px 20px',
        fontSize: '20px',
        textAlign: 'center',
        fontWeight: 600,
        '& span': {
            display: "block",
            maxWidth: '250px',
            paddingBottom: '20px',
            color: '#047d95',
            borderBottom: '2px solid #047d95',
            margin: '0 auto'
        }
    },
    inputWrap:{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px 0'
    },
    giftCardTable: {
        maxWidth: '900px',
        margin: '40px auto'
    }
})