import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';

import Navigation from '../../navigation';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from "../../store/types";
import {Auth} from "../../store/slices";
import Notifications from "../Notifications";

const Root = () => {
    const dispatch = useDispatch();
    const {
        auth
    } = useSelector<IStore, { auth:any }>(state => ({
        auth: state.auth.data
    }))
    useEffect(() => {
        dispatch<any>(Auth.getAuthenticatedUser());
    }, [])

  return (
    <BrowserRouter>
      <Navigation isLoggedIn={auth.isLoggedIn} userRole={auth.role} />
    </BrowserRouter>
  );
};

export default Root;
