import {getAuthenticatedUser, logOutUser} from "../../thunk";

export default {
    [getAuthenticatedUser.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getAuthenticatedUser.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload;
    },
    [getAuthenticatedUser.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [logOutUser.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [logOutUser.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        console.log(action.payload, 'Auth');
        state.data = action.payload;
    },
    [logOutUser.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
}
