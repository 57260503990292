import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { useStyles } from './styles';



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));
const OrderStatus = {
    1: {status: 'Created', color:'#00ccff54'},
    2: {status: 'Production', color:'#ffa500ab'},
    3: {status: 'Shipped', color:'rgba(56,183,2,0.9)'},
    4: {status: 'Delivered', color:'rgba(192,192,192,0.92)'},
    5: {status: 'Canceled', color:'#80808073'},
    6: {status: 'Error', color:'#ff000073'},
    7: {status: 'Partially Shipped', color:'#8ffa98'},
    8: {status: 'Hold', color:'rgba(224,0,255,0.45)'},
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        maxWidth: 400,
        border: '1px solid #dadde9',
    },
}));



export default function OrdersTable(props: any) {
    const { orders, paging, pagingUpdate } = props;
    console.log(orders);

    const classes = useStyles();
    const navigator = useNavigate();

    const handleChangePage = (event: unknown, newPage: number) => {
        pagingUpdate({...paging, page: newPage+1, perPage: paging.perPage})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        pagingUpdate({...paging, page: 1, perPage: +event.target.value})
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '65vh', position: 'relative'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                              Order ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                External ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                               Created
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Last Update
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Status
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                In ShipStation
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                On Hold
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Items
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                                Price
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                               Price With Shipment
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100 }}
                            >
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders
                            .map((order) => {
                                return (
                                    <StyledTableRow tabIndex={-1} key={order.orderId}>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order.orderId}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order.externalOrderId}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {formatDate(order.createdAt)}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {formatDate(order.updatedAt)}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            <div className={classes.storyStatus} style={{backgroundColor: `${OrderStatus[order.orderStatusId].color}`}}>
                                                {OrderStatus[order.orderStatusId].status}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            <span style={{color: `${order.shipstationCreated ? 'green': 'grey'}`,fontWeight: 600}}>
                                                  {order.shipstationCreated ? 'YES': 'NO' }
                                            </span>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            <span style={{color: `${order.onHold ? 'red': 'green'}`,fontWeight: 600}}>
                                                  {order.onHold ? 'YES': 'NO' }
                                            </span>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order?.orderItemQuantity}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order?.totalOrderPrice}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100 }}
                                        >
                                            {order?.totalOrderPriceWithShipment}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170 }}
                                        >
                                            <div className={classes.editButton}
                                                 onClick={() => {
                                                     // dispatch(ordersActions.clearOneOrderInfo());
                                                     navigator(`/${order.clientId}/orders/${order.orderId}/details`);
                                                 }}
                                            >Details</div>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={paging.total}
                rowsPerPage={paging.perPage}
                page={paging.page-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}