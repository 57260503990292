import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
   imageWrap: {
       width: '268px',
       height: '268px',
       position: "relative",
       display: "flex",
       // alignItems: 'center',
       // justifyContent: 'center',
       '& img': {
           display: "block",
           maxWidth: '100%',
           maxHeight: '100%',
           margin: '0 auto',
       },
       '&:hover $editButton': {
           display: 'block'
       }
   },
   popUpWrapper: {
       width: '100%',
       height: '100%',
       position: "fixed",
       display: "flex",
       alignItems: "center",
       justifyContent: "center",
       backgroundColor: "#808080c2",
       top: 0,
       left: 0,
       zIndex: 20,
   },
    popUp: {
        maxWidth: '600px',
        maxHeight: '600px',
        backgroundColor: 'white',
        padding: '40px 50px',
        borderRadius: '20px',
        position: "relative",
    },
    popUpImage:{
       width: '100%',
       maxHeight: '600px',
        marginBottom: '40px',
       '& img': {
           display: "block",
           maxWidth: '100%',
           maxHeight: '400px',
           margin: '0 auto',
       }
    },
    buttonsWrap: {
       display: "flex",
        minWidth: '450px',
        justifyContent: 'space-around'
    },
    blockWrap: {
       padding: '24px 15px 15px',
       margin: 8,
       border: '1px solid #047d95',
       borderRadius: '4px',
       position: "relative",

    },
    blockLabel: {
       position: 'absolute',
       top: '-12px',
       left: '20px',
       padding: '0 5px',
       fontSize: '1rem',
       color: "rgba(0, 0, 0, 0.6)",
       backgroundColor: "white",
    },
    editButton: {
       display: 'none',
       position: 'absolute',
       top: '10px',
       right: '10px',
    },
    popUpLoader: {
        width: '100%',
        height: '100%',
        backgroundColor: '#80808080',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 100,
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        borderRadius: '20px',
    },
    textWrapper: {
        display:"flex",
        width:'100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '200px'
    }


})