import {getUsers} from "../../thunk";

export default {
    [getUsers.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getUsers.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload;
    },
    [getUsers.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
}
