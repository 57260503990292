import * as React from 'react';
import {useStyles} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {Button, CircularProgress} from "@mui/material";
import {createGiftCard, getS3Url} from "../../../api";
import {Notifications, Stories} from "../../../store/slices";
import imageCompression from "browser-image-compression";



export default function StoryImage(props: any) {
    const {onChange, data} = props
    const [isPopUp, setPopUp] = useState(false)
    const [image, setImage] = useState<any| null>()
    const [pending, setPending] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    async function inputImageHandler (event) {
        setPending(true);
        const imageFile = event.target.files[0];
        if (!imageFile) {
            setPending(false);
            return false;
        }

        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1940,
            useWebWorker: true,
        }
        try {
            const compressedFile = await imageCompression(imageFile, options);
            await setImage(compressedFile); // write your own logic
            setPending(false);
        } catch (error) {
            console.log(error);
            setPending(false);
        }

    }
    function handleUpload() {
        if (!image) {
            return;
        }

        const reader = new FileReader();
        reader.readAsArrayBuffer(image);

        reader.onload = function() {
            if (reader.result !== null) {
                // @ts-ignore
                const blob = new Blob([reader.result], { type: image.type });
                // Upload the blob here...
                uploadImage(blob);
            }
        };
    }

    const uploadImage = async (blob) => {
        setPending(true);
        try {
            await getS3Url({
                blob,
                onSuccess: (result) => {
                    onChange({target:{name:'imageLink', value: result.url}})
                    dispatch(Notifications.setNotifications({text: 'Image uploaded, don\'t forget save changes!', type: 'warning'}))
                    setPending(false);
                    popUpHandler();
                }
            });
        } catch (error) {
            dispatch(Notifications.setNotifications({text: 'Something went wrong, please try again later', type: 'error'}))
            setPending(false);
        }
    };

    const popUpHandler = (cancel?: boolean) =>{
        cancel && setImage(null)
        setPopUp(prevState => !prevState)
    }
    return (
        <div className={classes.blockWrap}>
            <div className={classes.blockLabel}>Story Image</div>
            <div className={classes.imageWrap}>
                <Button
                    className={classes.editButton}
                    variant="contained"
                    onClick={()=>popUpHandler()}
                >
                    Edit
                </Button>
                {image ? <img src={URL.createObjectURL(image)}/> : <img src={data.image} />}
            </div>
            {isPopUp &&
                <div className={classes.popUpWrapper}>
                    <div className={classes.popUp}>
                        {pending && <div className={classes.popUpLoader}><CircularProgress size={80} /></div>}
                        <div className={classes.popUpImage}>
                            {image ? <img src={URL.createObjectURL(image)}/> : <img src={data.image} />}
                        </div>
                        <div className={classes.buttonsWrap}>
                            <Button
                                variant="outlined"
                                onClick={()=>popUpHandler(true)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                component="label"
                            >
                                Chose another
                                <input
                                    accept="image/*"
                                    name="image"
                                    type="file"
                                    onChange={inputImageHandler}
                                    hidden
                                />
                            </Button>
                            {image &&
                                <Button
                                variant="contained"
                                onClick={handleUpload}
                            >
                                Use this
                            </Button>
                            }

                        </div>
                    </div>
                </div>
            }
        </div>
    );
}