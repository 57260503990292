import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    storyStatus: {
        padding:[5, 15],
        width: 'fit-content',
        margin: '0 auto',
        borderRadius: '14px',
    },
    editButton: {
        padding:[8, 30],
        width: 'fit-content',
        margin: '0 auto',
        backgroundColor: '#047d95',
        color: 'white',
        cursor:'pointer',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#005566',
        }
    },
    linkButton: {
        padding:[8, 10],
        width: 'fit-content',
        margin: '0 auto',
        backgroundColor: '#01ac9e',
        color: 'white',
        cursor:'pointer',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#137366',
        }
    },
})