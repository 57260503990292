import {addOrders, getAnalytics3d, getMockups3d, addMockups3d} from "../../thunk";

const getID = (id) => {
    return `a${id}a`
}

export default {
    [getAnalytics3d.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getAnalytics3d.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.data = action.payload;
        state.queryParams = {...action.meta.arg ,page: +action.payload.page, perPage: +action.payload.perPage, total: +action.payload.totalCount}
    },
    [getAnalytics3d.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [getMockups3d.pending as any]: (state) => {
        state.status = 'loading';
        state.error = null;
    },
    [getMockups3d.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.mockups = action.payload;
        state.mockups.queryParams = {...action.meta.arg ,
            page: +action.payload.page,
            perPage: +action.payload.perPage,
            total: +action.payload.totalCount,
            // lastEvaluatedKey: action.payload.lastEvaluatedKey
        }
    },
    [getMockups3d.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
    [addMockups3d.pending as any]: (state) => {
        // state.status = 'loading';
        state.error = null;
    },
    [addMockups3d.fulfilled as any]: (state, action) => {
        state.status = 'resolved';
        state.mockups.result = [...state.mockups.result,...action.payload.result];
        state.mockups.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        state.mockups.queryParams = {...action.meta.arg,
            page: +action.payload.page,
            perPage: +action.payload.perPage,
            total: +action.payload.totalCount,
            // lastEvaluatedKey: action.payload.lastEvaluatedKey
        }
    },
    [addMockups3d.rejected as any]: (state, action) => {
        state.status = 'rejected';
        state.error = action.payload;
    },
}
