import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    authorWrap: {
        paddingBottom: '20px',
        position: 'relative'
    },
    authorHeader: {
        width:'100%',
        padding: '0 20px 20px',
        fontSize: '20px',
        textAlign: 'center',
        fontWeight: 600,
        '& span': {
            display: "block",
            maxWidth: '250px',
            paddingBottom: '20px',
            color: '#047d95',
            borderBottom: '2px solid #047d95',
            margin: '0 auto'
        }
    },
    inputWrap:{
        display: 'flex',
        justifyContent: 'center',
        padding: '20px'
    },
    storyLink: {
        position: 'absolute',
        top: 0,
        right: '10px',
        '& a': {
            fontWeight: 600,
        }
    },
    totalPrising:{
        width:'100%',
        padding: '60px 30px 30px',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'flex-end',
        '&>div': {
            fontSize: '20px',
            padding: '10px',
            borderBottom:'2px solid #047d95'
        }
    },
    editButton: {
        padding:[8, 30],
        width: 'fit-content',
        margin: '0 auto',
        backgroundColor: '#047d95',
        color: 'white',
        cursor:'pointer',
        fontWeight: 600,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#005566',
        }
    },
})