import * as React from 'react';
import { useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import { useStyles } from './styles';
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import {useState} from "react";
import {createGiftCard} from "../../api";
import {Notifications, Stories} from "../../store/slices";
import {useDispatch} from "react-redux";



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#0000000a',
    },
}));
const StoryStatus = {
    1: {status: 'Created', color:'#00ccff54'},
    2: {status: 'Pending review', color:'#ffa500ab'},
    3: {status: 'Approved', color:'#4bd30899'},
    4: {status: 'Rejected', color:'#80808073'},
    5: {status: 'Test Data', color:'#ff000073'},
    6: {status: 'Error on review', color:'#ff000073'},
    7: {status: 'E!!!!!!!', color:'#ff000073'},
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        maxWidth: 620,
        border: '1px solid #dadde9',
    },
}));

type SelectedItem = {
    storyId: any;
    authorId: any;
};


export default function StoriesTable(props: any) {
    const { stories, paging, pagingUpdate } = props;

    const [pending, setPending] = useState(false);

    const [giftInputValue, setGiftInputValue] = useState<number>(0);

    const [isPopUp, setIsPopUp] = useState(false);
    const [isPublishPopUp, setIsPublishPopUp] = useState(false);
    const dispatch = useDispatch();

    const [selectedId, setSelectedId] =useState<SelectedItem[]>([]);

    const classes = useStyles();
    const navigator = useNavigate();

    const handleCheckBoxChange = (e, storyId, authorId) =>{
        if(e.target.checked){
            setSelectedId([...selectedId, {storyId, authorId}])
        } else {
            setSelectedId(selectedId.filter(item => item.storyId !== storyId));
        }
    }

    const createGiftCart = async (data, inputValue) => {
        try {
            await createGiftCard({
                data: {
                    gfStoryId: data.storyId,
                    gfAuthorId: data.authorId,
                    amount: inputValue,
                },
                onSuccess: async () => {
                    dispatch(Notifications.setNotifications({text: "Gift card created successful", type: 'success'}))
                }
            });
        } catch (error) {
            dispatch(Notifications.setNotifications({text:`Something went wrong ID:${data.storyId}`, type: 'error'}))
        }
    };

    const processGiftCards = (items, inputValue, index = 0) => {
        if (index >= items.length) {
            // setSelectedId([]);
            setGiftInputValue(0);
            setPending(false);
            return;
        };

        createGiftCart(items[index], inputValue);
        // console.log(items[index], inputValue);

        setTimeout(() => {
            processGiftCards(items, inputValue, index + 1);
        }, 400);
    };

    const publishStory = async (data) => {
        try {
            dispatch<any>(Stories.updateStory({id: data.storyId, formData: {isPublished: 1}})).then(()=>{})
        } catch (error) {
            dispatch(Notifications.setNotifications({text:`Something went wrong ID:${data.storyId}`, type: 'error'}))
        }
    };


    const processPublish = (items, index = 0) => {
        if (index >= items.length) {
            // setSelectedId([]);
            setGiftInputValue(0);
            setPending(false);
            return;
        };

        publishStory(items[index]);

        setTimeout(() => {
            processPublish(items, index + 1);
        }, 400);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        pagingUpdate({...paging, page: newPage+1, perPage: paging.perPage})
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        pagingUpdate({...paging, page: 1, perPage: +event.target.value})
    };

    return (
        <>
            {isPopUp && <div style={{position:'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#00000078',
                display: 'flex',
                alignItems: 'center',
                zIndex: 3,
                top: 0,
                left:0,
                justifyContent: 'center'}}>
                <div style={{width: 'auto', backgroundColor: 'white', borderRadius: '30px', padding: '20px 40px 40px', textAlign:'center'}}>
                        <h2>Create gift card for {selectedId.length} stories</h2>
                    <TextField label="Amount" type="number" inputProps={{ inputMode: 'numeric', pattern: '^[1-9]\\d*$' }} variant="outlined" sx={{ m: 1, width: '30ch'}}
                               onChange={(e)=>setGiftInputValue(Number(e.target.value))}/>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: '20px'}}>
                            <Button   variant="contained"
                                      color="secondary"
                                      component="label"
                                      disabled={giftInputValue == 0 }
                                      onClick={()=>{setIsPopUp(false); setPending(true);processGiftCards(selectedId, giftInputValue)}}
                            >Create</Button>
                            <Button
                                variant="outlined"
                                onClick={()=>{setIsPopUp(false); setGiftInputValue(0)}}>Cancel</Button>
                        </div>
                </div>
            </div>}

            {isPublishPopUp && <div style={{position:'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#00000078',
                display: 'flex',
                alignItems: 'center',
                zIndex: 3,
                top: 0,
                left:0,
                justifyContent: 'center'}}>
                <div style={{width: 'auto', backgroundColor: 'white', borderRadius: '30px', padding: '20px 40px 40px', textAlign:'center', minWidth:'350px'}}>
                    <h2>Publish {selectedId.length} stories</h2>
                    <div style={{display: 'flex', width: '100%', justifyContent: 'space-around', marginTop: '20px'}}>
                        <Button   variant="contained"
                                  color="secondary"
                                  component="label"
                                  onClick={()=>{setIsPublishPopUp(false); setPending(true);processPublish(selectedId)}}
                        >Publish</Button>
                        <Button
                            variant="outlined"
                            onClick={()=>{setIsPublishPopUp(false)}}>Cancel</Button>
                    </div>
                </div>
            </div>}
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: '65vh', position: 'relative'}}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 30, padding: '16px 10px' }}
                            >
                                {pending && <div style={{position:"absolute", left: "5px", top: '2px', zIndex:'3', backgroundColor: 'white'}}><div className={classes.loader}></div></div>}
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 50, padding: '16px 10px' }}
                            >
                              ID
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170, padding: '16px 10px' }}
                            >
                                Image
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170, padding: '16px 10px' }}
                            >
                                Video
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100, padding: '16px 10px' }}
                            >
                                Date
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170, padding: '16px 10px' }}
                            >
                                Author Name
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 170, padding: '16px 10px' }}
                            >
                                Story Status
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 100, padding: '16px 10px' }}
                            >
                                Is Published
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 150, padding: '16px 10px' }}
                            >
                                <div style={{position: 'absolute', top: '10px', right:'10px', minWidth:'150px'}}>
                                    {selectedId.length > 0 &&
                                        <div className={classes.editButton}
                                             onClick={() => {
                                                 setIsPublishPopUp(true);
                                                 // dispatch(ordersActions.clearOneOrderInfo());
                                                 // navigator(`/stories/${story.storyId}/details`);
                                             }}
                                        >Publish</div>}

                                </div>
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{ minWidth: 150 , padding: '16px 10px'}}
                            >
                               <div style={{position: 'absolute', top: '10px', right:'10px', minWidth:'150px'}}>
                                   {selectedId.length > 0 &&
                                       <div className={classes.editButton}
                                            onClick={() => {
                                                setIsPopUp(true);
                                                // dispatch(ordersActions.clearOneOrderInfo());
                                                // navigator(`/stories/${story.storyId}/details`);
                                            }}
                                       >Gift cards</div>}

                               </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stories
                            .map((story) => {
                                return (
                                    <StyledTableRow tabIndex={-1} key={story.storyId}>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 30, padding: '16px 10px' }}
                                        >
                                            <Checkbox checked={selectedId.some(item => item.storyId === story.storyId)} onChange={(e)=>handleCheckBoxChange(e,story.storyId, story.authorId)} />
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 50, padding: '16px 10px' }}
                                        >
                                            {story.storyId}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170, padding: '5px 10px'  }}
                                        >
                                            <div>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <img style={{maxHeight:'400px',maxWidth: '380px', width: 'auto'}} src={story.imageLink} />
                                                        </React.Fragment>
                                                    }
                                                >
                                                <img style={{maxHeight:'65px', width: 'auto'}} src={story.imageLink} />
                                                </HtmlTooltip>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170, padding: '5px 10px'  }}
                                        >
                                            <div>
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            {/*<iframe*/}
                                                            {/*    src={`https://iframe.mediadelivery.net/embed/${story.videoLink}?autoplay=false`}*/}
                                                            {/*    loading="lazy" style={{border: 'none', width:'600px', height: 'auto', maxWidth: '100%', maxHeight: '100%', minHeight:'600px'}}*/}
                                                            {/*    allow="accelerometer; gyroscope; encrypted-media; picture-in-picture;"*/}
                                                            {/*    allowFullScreen>*/}
                                                            {/*</iframe>*/}
                                                            <video controls width="600" height="360">
                                                                <source data-type="720" src={`https://vz-e462438a-03a.b-cdn.net/${story.videoLink.substring(story.videoLink.indexOf('/') + 1)}/play_720p.mp4#t=0.001`} type="video/mp4"/>
                                                                <source data-type="480" src={`https://vz-e462438a-03a.b-cdn.net/${story.videoLink.substring(story.videoLink.indexOf('/') + 1)}/play_480p.mp4#t=0.001`} type="video/mp4"/>
                                                                <source data-type="360" src={`https://vz-e462438a-03a.b-cdn.net/${story.videoLink.substring(story.videoLink.indexOf('/') + 1)}/play_360p.mp4#t=0.001`} type="video/mp4"/>
                                                               <source data-type="240" src={`https://vz-e462438a-03a.b-cdn.net/${story.videoLink.substring(story.videoLink.indexOf('/') + 1)}/play_240p.mp4#t=0.001`} type="video/mp4"/>
                                                            </video>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <img style={{maxHeight:'65px', width: 'auto'}} src={story.imageLink} />
                                                </HtmlTooltip>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100, padding: '16px 10px' }}
                                        >
                                            {formatDate(story.date)}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170, padding: '16px 10px' }}
                                        >
                                            {story.firstName}
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 170, padding: '16px 10px' }}
                                        >
                                            <div className={classes.storyStatus} style={{backgroundColor: `${StoryStatus[story.statusId].color || 'green'}`}}>
                                                {StoryStatus[story.statusId].status}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 100, padding: '16px 10px' }}
                                        >
                                            <span style={{color: `${story.isPublished ? 'green': 'grey'}`,fontWeight: 600}}>
                                                  {story.isPublished ? 'Published': 'Not Published' }
                                            </span>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 150, padding: '16px 10px' }}
                                        >
                                            <a href={`https://dash.bunny.net/stream/${story.videoLink.substring(0, story.videoLink.indexOf("/"))}/library/videos?page=1&search=${story.videoLink.substring(story.videoLink.indexOf("/")+1)}&videoId=${story.videoLink.substring(story.videoLink.indexOf("/")+1)}`}
                                               target="_blank"
                                               className={classes.linkButton}
                                            >Video Link</a>
                                        </TableCell>
                                        <TableCell
                                            align={'center'}
                                            style={{ minWidth: 150 }}
                                        >
                                            <div className={classes.editButton}
                                                 onClick={() => {
                                                     // dispatch(ordersActions.clearOneOrderInfo());
                                                     navigator(`/stories/${story.storyId}/details`);
                                                 }}
                                            >Edit</div>
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 15, 25]}
                component="div"
                count={paging.total}
                rowsPerPage={paging.perPage}
                page={paging.page-1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
        </>
    );
}